import { AuthContext } from "../models/AuthContext";
import CurrentUser from "../models/CurrentUser";
import { SingleSignOnType } from "../models/SingleSignOnType";

export class ProfileAccessHelper {
    public static canAccessNotifications(currentUser: CurrentUser): boolean {
        return this.isUserPasswordOrSSOWithoutFallbackAuthenticated(currentUser);
    }

    public static canManageNotificationSettings(currentUser: CurrentUser): boolean {
        return this.isUserPasswordOrSSOWithoutFallbackAuthenticated(currentUser);
    }

    public static canManageSecurity(currentUser: CurrentUser): boolean {
        return this.isUserPasswordOrSSOWithoutFallbackAuthenticated(currentUser);
    }

    public static canManagePassword(currentUser: CurrentUser): boolean {
        return this.isUserPasswordOrSSOWithoutFallbackAuthenticated(currentUser);
    }

    public static canEditProfile(currentUser: CurrentUser): boolean {
        return this.isUserPasswordOrSSOWithoutFallbackAuthenticated(currentUser);
    }

    public static canManageProfile = (currentUser: CurrentUser): boolean => {
        return currentUser.authContext === AuthContext.Password || currentUser.authContext === AuthContext.Impersonation;
    }

    private static isUserPasswordOrSSOWithoutFallbackAuthenticated(currentUser: CurrentUser): boolean {
        const isUserPasswordAuthenticated = currentUser.authContext === AuthContext.Password;
        const isUserSSOWithoutFallbackAuthenticated = (currentUser.authContext === AuthContext.SingleSignOn && currentUser.singleSignOnType != SingleSignOnType.Fallback);
        return isUserPasswordAuthenticated || isUserSSOWithoutFallbackAuthenticated;
    }
}