import * as React from 'react';
import { Route, Switch } from 'react-router';
import Logout from '../components/Logout/Logout';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import { IStartPageProps } from './IStartPageProps';
import Layout from './Layout/Layout';
import Login from './Login/Login';
import ResetPassword from './ResetPassword/ResetPassword';
import styles from './StartPage.module.scss';
import Impersonate from './Login/Impersonate/Impersonate';
import App from './App/App';
import EnforceMfa from './Profile/EnforceMfa/EnforceMfa';
import ConnectionContext from '../utils/ConnectionContext';

export default class StartPage extends React.Component<IStartPageProps> {
    private connectionContext: ConnectionContext;
    constructor(props: IStartPageProps) {
        super(props);
        this.connectionContext = new ConnectionContext(props.apiUrl);
    }

    public render(): React.ReactNode {
        return (
            <div className={styles.startPage}>
                <Layout>
                    <Switch>
                        <Route exact path="/login" component={(props) => <Login routeProps={props} connectionContext={this.connectionContext} />} />
                        <Route exact path="/forgotpassword" component={(props) => <ForgotPassword routeProps={props} connectionContext={this.connectionContext} />} />
                        <Route exact path="/resetpassword" component={(props) => <ResetPassword routeProps={props} connectionContext={this.connectionContext} />} />
                        <Route exact path="/logout" component={() => <Logout />} />
                        <Route exact path="/enablemfa" component={(props) => <EnforceMfa routeProps={props} />} />
                        <Route exact path="/impersonate" component={(props) => <Impersonate routeProps={props} connectionContext={this.connectionContext} />} />
                        <Route path="/" component={(props) => <App routeProps={props} />} />
                    </Switch>
                </Layout>
            </div>);
    }
}
