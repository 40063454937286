import * as React from "react";
import { Callout } from "@fluentui/react/lib/Callout";
import { Text } from "@fluentui/react/lib/Text";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import styles from "./ConfirmDialog.module.scss";
import { IConfirmDialogProps } from "./IConfirmDialogProps";

export class ConfirmDialog extends React.Component<IConfirmDialogProps> {
    constructor(props) {
        super(props)
    }

    render() {
        const { title, content, onClose, visible, target, onConfirm, onDecline, confirmText, declineText } = this.props;
        return visible && <Callout
            className={styles.Dialog}
            role="alertdialog"
            gapSpace={0}
            target={target}
            onDismiss={onClose}
        >
            <div className={styles.Header}>
                <Text className={styles.Title}>
                    {title}
                </Text>
            </div>
            <div className={styles.Content}>
                <Text>
                    {content}
                </Text>
            </div>
            <div className={styles.Action}>
                <div>
                    <PrimaryButton className={styles.Button} onClick={() => { onConfirm && onConfirm(); onClose(); }}>{confirmText ? confirmText : "Confirm"}</PrimaryButton>
                    <DefaultButton className={styles.Button} onClick={onDecline ? onDecline : onClose}>{declineText ? declineText : "Close"}</DefaultButton>
                </div>
            </div>
        </Callout>
    }
}