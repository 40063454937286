import React, { useState, useEffect } from "react";
import QRCode from "qrcode";
import styles from "../QRCodeGenerator/QRCodeComponent.module.scss";
import { IQRCodeComponentProps } from "./IQRCodeComponentProps";

export default function QRCodeComponent(props: IQRCodeComponentProps) {
    const [qrCode, setQrCode] = useState("");

    useEffect(() => {
        QRCode.toDataURL(props.content, (err, url) => {
            if (err) {
                console.error('Error generating QR code', err);
            } else {
                setQrCode(url);
            }
        });
    }, [props.content]);

    return (
        <div> 
            <img className={styles.QrCode} src={qrCode} alt="Qr Code" />
        </div>
    )
}