import * as React from "react";
import { DefaultButton, MessageBar, MessageBarType } from "@fluentui/react/lib";
import styles from "./EnforceMfa.module.scss";
import { Constants } from "../../Login/Constants";
import { Loader } from "../../Common/Loader/Loader";
import { LoaderType } from "../../Common/Loader/LoaderType";
import { IEnforceMfaProps } from "./IEnforceMfaProps";
import { IEnforceMfaState } from "./IEnforceMfaState";
import { ProfileDataManager } from "../ProfileDataManager";
import { ManageSecurity } from "../ManageSecurity/ManageSecurity";
import { SecuritySettings } from "../models/SecuritySettings";

export default class EnforceMfa extends React.Component<IEnforceMfaProps, IEnforceMfaState> {
    private dataManager: ProfileDataManager;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            hasError: false,
            multifactorEnabled: false
        };
        this.dataManager = new ProfileDataManager();
    }

    private setLoading = (loading: boolean): void => {
        this.setState((prevState: IEnforceMfaState): IEnforceMfaState => {
            prevState.loading = loading;
            return prevState;
        });
    };

    public async componentDidMount(): Promise<void> {
        this.setLoading(true);
        await this.loadSecuritySettings();
        this.setLoading(false);
    }

    public render(): React.ReactNode {
        const {
            multiFactorSettings,
            loading,
            multifactorEnabled: allSet,
            hasError
        } = this.state;

        if (loading) {
            return <Loader type={LoaderType.Fallback} />;
        }

        return (
            <div className={styles.container}>
                <div className={styles.page}>
                    <div className={styles.body}>
                        <div className={styles.content}>
                            <div className={styles.form}>
                                <div className={styles.formName}>
                                    {!allSet ? "COMPLETE YOUR MULTIFACTOR SETUP" : "MULTIFACTOR SETUP COMPLETED"}
                                </div>
                                {multiFactorSettings && (
                                    <ManageSecurity
                                        multiFactorSettings={multiFactorSettings}
                                        isEnforced={true}
                                        onMultiFactorSettingEnabled={this.onMultiFactorEnabled}
                                    />
                                )}
                                {
                                    allSet && (
                                        <div className={styles.alignCenter}>
                                            <DefaultButton onClick={this.handleRedirect()} text="Return to dashboard" />
                                        </div>
                                    )
                                }
                                {
                                    hasError && (
                                        <React.Fragment>
                                            <MessageBar messageBarType={MessageBarType.error}>
                                                Unexpected error occured while setting up multifactor authentication, Please try again.
                                            </MessageBar>
                                            <div className={styles.alignCenter}>
                                                <DefaultButton onClick={this.handleRedirect('/login')} text="Return to Login" />
                                            </div>
                                        </React.Fragment>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.introduction}>
                    <div className={styles.header}>
                        <div className={styles.content}>
                            <div>
                                TO <strong>MANAGE</strong>, <strong>CONTROL</strong>
                            </div>
                            <div>
                                AND GET AN <strong>OVERVIEW</strong>
                            </div>
                            <div>OF YOUR IP PORTFOLIO</div>
                        </div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.content}></div>
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.content}></div>
                    </div>
                </div>
            </div>
        );
    }

    private loadSecuritySettings = async (): Promise<void> => {
        try {
            const securitySettingsResult: SecuritySettings = await this.dataManager.getSecuritySettings();
            this.setState((prevState: IEnforceMfaState): IEnforceMfaState => {
                prevState.multiFactorSettings = securitySettingsResult;
                prevState.loading = false;
                prevState.hasError = false;
                return prevState;
            });
        }
        catch (e) {
            this.setState((prevState: IEnforceMfaState): IEnforceMfaState => {
                prevState.loading = false;
                prevState.hasError = true;
                return prevState;
            });
        }
    }

    private onMultiFactorEnabled = (): void => {
        this.setState((prevState: IEnforceMfaState): IEnforceMfaState => {
            prevState.multifactorEnabled = true;
            return prevState;
        });
    }

    private handleRedirect = (pathName?: string): React.MouseEventHandler<DefaultButton> | undefined => {
        return () => {
            const history = this.props.routeProps["history"];
            if (pathName !== undefined) {
                history.push && history.push(pathName);
                return;
            }
            let userPath: any = localStorage.getItem(Constants.Tokens.RedirectUrl);
            if (userPath && userPath !== "/login") {
                localStorage.removeItem(Constants.Tokens.RedirectUrl);
                history.push && history.push(userPath);
                return;
            }
            history.push && history.push("/dashboard");
            return;
        }
    }
}