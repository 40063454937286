import { DefaultButton, Modal, PrimaryButton } from "@fluentui/react";
import PasswordStrengthResource from "../PasswordStrength.resource";
import styles from "./PasswordConfirmationDialog.module.scss";
import * as React from "react";
import PasswordStrengthHelper from "../PasswordStrengthHelper";
import { IPasswordConfirmationDialogProps } from "./Interface/IPasswordConfirmationDialogProps";
import { PasswordStrengthTypeEnum } from "../../../../models/PasswordStrengthTypeEnum";

export const PasswordConfirmationDialog: React.FunctionComponent<IPasswordConfirmationDialogProps> = (props: IPasswordConfirmationDialogProps): JSX.Element => {
    const passwordStrengthValueDisplayClass = getPasswordStrengthValueStylingClass(props.passwordStrengthDetails.strengthType);
    return (
        <Modal
            styles={{
                root: styles.passwordConfirmationDialog,
                main: styles.main,
                scrollableContent: styles.scrollableContent
            }}
            isOpen={props.shouldShowPasswordConfirmationDialog}>
            <div className={styles.container}>
                <div className={styles.passwordStrengthDetails}>
                    <div className={styles.passwordStrengthDisplayControl}>
                        <label className={styles.passwordStrengthDisplayLabel}>{PasswordStrengthResource.labels.passwordStrengthLabel}</label>
                        <span className={passwordStrengthValueDisplayClass}>{props.passwordStrengthDetails.strengthText}</span>
                    </div>
                    <div className={styles.passwordCrackTimeDetails} >
                        <label className={styles.passwordCrackTimeDetailsLabel}>{PasswordStrengthResource.labels.estimatedTimeToCrackLabel}</label>
                        <span className={styles.passwordCrackTimeDetailsValue} >{props.passwordStrengthDetails.estimatedTimeToCrack}</span>
                    </div>
                </div>
                <h4 className={styles.passwordStrentghValidationInformation}>
                    {PasswordStrengthResource.informations.validationMessage}
                </h4>
                <div className={styles.confirmationMessage}>
                    <div >
                        <h4 className={styles.confirmationMessageLabel}>
                            {PasswordStrengthResource.informations.confirmationMessage}
                        </h4>
                        <div className={styles.confirmationMessageButtonGroup}>
                            <PrimaryButton
                                disabled={props.isLoading}
                                onClick={props.onSubmitPasswordChangeRequest}>{PasswordStrengthResource.labels.buttons.yesButtonText}</PrimaryButton>
                            <DefaultButton onClick={props.onCancelWeakPasswordConfirmation}>
                                {PasswordStrengthResource.labels.buttons.noButtonText}
                            </DefaultButton>
                        </div>
                    </div>
                </div>
            </div >
        </Modal>);
}

const getPasswordStrengthValueStylingClass = (passwordStrengthType: PasswordStrengthTypeEnum): string => {
    switch (passwordStrengthType) {
        case PasswordStrengthTypeEnum.VeryWeak:
            return styles.veryWeakPasswordStrengthValue;
        case PasswordStrengthTypeEnum.Weak:
            return styles.weakPasswordStrengthValue;
        default:
            return '';
    }
}