import { ChoiceGroup, DefaultButton, IChoiceGroupOption, Icon, MessageBar, MessageBarButton, MessageBarType, ProgressIndicator, TextField } from "@fluentui/react/lib";
import * as React from "react";
import { ConfirmDialog } from "../ConfirmDialog/ConfirmDialog";
import { ProfileDataManager } from "../ProfileDataManager";
import { IMessageState, Notifier } from "../Notifier/Notifier";
import styles from "../Profile.module.scss";
import { strings } from "../strings";
import { IManageSecurityProps } from "./IManageSecurityProps";
import { IManageSecurityState } from "./IManageSecurityState";
import { MultiFactorAuthenticationType } from "../models/MultiFactorAuthenticationType";
import { SecuritySettingsActionState } from "../models/SecuritySettingsActionState";
import { IApiError } from "../models/IApiError";
import { SetupToEnablePushAuthenticationStatusCode } from "../models/SetupToEnablePushAuthenticationStatusCode";
import QRCodeComponent from "../../QRCodeGenerator/QRCodeComponent";
import { ProfileAccessHelper } from "../../../utils/ProfileAccessHelper";

export class ManageSecurity extends React.Component<IManageSecurityProps, IManageSecurityState> {
    private dataManager: ProfileDataManager;
    static defaultProps: { isEnforced: boolean; };

    constructor(props) {
        super(props);
        this.state = {
            multiFactorSettings: {
                multiFactorEnabled: false,
                requireMultiFactor: false,
                multiFactorAuthenticationType: MultiFactorAuthenticationType.TotpAuthentication
            },
            initialMultiFactorAuthenticationType: MultiFactorAuthenticationType.TotpAuthentication,
            loading: false,
            verificationCode: "",
            sharedAuthKey: "",
            qrCodeContent: "",
            pushAuthApprovalRequestId: "",
            pushAuthApprovalCode: "",
            backupCodes: [],
            verifyingPushAuth: false,
            securityActionState: SecuritySettingsActionState.Information,
            showDisablePushAuth: false,
            showDisableTotpAuth: false,
            showActivateTotpAuth: false,
            showActivatePushAuth: false,
        };
        this.dataManager = new ProfileDataManager();
    }

    componentDidMount() {
        const { multiFactorSettings, isEnforced } = this.props;
        if (multiFactorSettings) {
            this.setState({
                multiFactorSettings,
                initialMultiFactorAuthenticationType: multiFactorSettings.multiFactorAuthenticationType
            });
            return;
        }
        this.setState({ loading: true }, () => {
            this.dataManager.getSecuritySettings().then((multiFactorSettings) => {
                this.setState({ multiFactorSettings, loading: false, initialMultiFactorAuthenticationType: multiFactorSettings.multiFactorAuthenticationType });
            }).catch((error: IApiError) => {
                this.setState({ loading: false });
            });
        });
    }

    postMessage = (messageMetadata: IMessageState) => {
        this.notifier && this.notifier.postMessage && this.notifier.postMessage(messageMetadata);
    }

    onBackClick = (event, securityActionState) => {
        event.preventDefault();
        this.setState({ securityActionState });
    }

    setLoading = (callback?: () => void) => {
        this.setState({ loading: true }, callback);
    }

    onActivateTotpAuthenticationActivate = () => {
        this.setLoading(() => {
            this.dataManager.getTotpEnableResource()
                .then((result) => {
                    const { sharedAuthKey, qrCodeContent } = result;
                    this.setState({ sharedAuthKey, qrCodeContent, securityActionState: SecuritySettingsActionState.VerifyTotpAuth, loading: false });
                }).catch((error) => {
                    const e: { code: number | undefined, body: Promise<any> | undefined } | null = error as { code: number | undefined, body: Promise<any> | undefined } | null;

                    if (e != null && e.code != undefined) {
                        let errorContent: any = e.body;
                        if (errorContent != undefined) {
                            this.postMessage({
                                message: errorContent.message,
                                level: "error"
                            });
                        }
                    }
                    this.setState({ loading: false });
                });
        }
        );
    }

    onVerifyAndEnableTotpAuthentication = () => {
        const { verificationCode, multiFactorSettings } = this.state;
        let { initialMultiFactorAuthenticationType } = this.state;
        if (!verificationCode) {
            this.postMessage({
                message: strings.EnterVerificationCode,
                level: "error"
            })
            return;
        }
        this.setLoading(() => {
            this.dataManager.verifyAndEnableTotpAuthentication(verificationCode).then((result) => {
                const { backupCodes } = result;
                initialMultiFactorAuthenticationType = MultiFactorAuthenticationType.TotpAuthentication;
                multiFactorSettings.multiFactorEnabled = true;
                multiFactorSettings.multiFactorAuthenticationType = MultiFactorAuthenticationType.TotpAuthentication;
                this.setState({ backupCodes, securityActionState: SecuritySettingsActionState.VerifiedTotpAuth, loading: false, multiFactorSettings, initialMultiFactorAuthenticationType }, () => {
                    this.postMessage({
                        message: strings.TotpAuthenticationEnabledNotification,
                        level: "success"
                    });
                });
            }).catch((error) => {
                const e: { code: number | undefined, body: Promise<any> | undefined } | null = error as { code: number | undefined, body: Promise<any> | undefined } | null;

                if (e != null && e.code != undefined) {
                    let errorContent: any = e.body;
                    if (errorContent != undefined) {
                        this.postMessage({
                            message: errorContent.message,
                            level: "error"
                        });
                    }
                }
                multiFactorSettings.multiFactorAuthenticationType = initialMultiFactorAuthenticationType;
                this.setState({ loading: false, multiFactorSettings });
            });
        });
    }

    onEnforceAndEnableTotpAuthentication = () => {
        const { verificationCode, multiFactorSettings } = this.state;
        let { initialMultiFactorAuthenticationType } = this.state;
        const { onMultiFactorSettingEnabled } = this.props;
        if (!verificationCode) {
            this.postMessage({
                message: strings.EnterVerificationCode,
                level: "error"
            });
            return;
        }
        this.setLoading(() => {
            this.dataManager.enforceAndEnableTotpAuthentication(verificationCode).then((result) => {
                const { backupCodes, status, reason, currentUser } = result;
                multiFactorSettings.multiFactorEnabled = true;
                initialMultiFactorAuthenticationType = MultiFactorAuthenticationType.TotpAuthentication;
                multiFactorSettings.multiFactorAuthenticationType = MultiFactorAuthenticationType.TotpAuthentication;
                this.setState({ backupCodes, securityActionState: SecuritySettingsActionState.VerifiedTotpAuth, loading: false, multiFactorSettings, initialMultiFactorAuthenticationType }, () => {
                    onMultiFactorSettingEnabled && onMultiFactorSettingEnabled({ status, reason, currentUser });
                    this.postMessage({
                        message: strings.TotpAuthenticationEnabledNotification,
                        level: "success"
                    });
                });
            }).catch((error) => {
                const e: { code: number | undefined, body: Promise<any> | undefined } | null = error as { code: number | undefined, body: Promise<any> | undefined } | null;

                if (e != null && e.code != undefined) {
                    let errorContent: any = e.body;
                    if (errorContent != undefined) {
                        this.postMessage({
                            message: errorContent.message,
                            level: "error"
                        });
                    }
                }
                multiFactorSettings.multiFactorAuthenticationType = initialMultiFactorAuthenticationType;
                this.setState({ loading: false, multiFactorSettings });
            });
        });
    }

    onDisableMultiFactor = () => {
        this.setLoading(() => {
            this.dataManager.disableMultiFactorAuthentication().then((result) => {
                let { multiFactorSettings } = this.state;
                multiFactorSettings.multiFactorEnabled = false;
                this.setState({ securityActionState: SecuritySettingsActionState.Information, loading: false, multiFactorSettings }, () => {
                    this.postMessage({
                        message: strings.TotpAuthenticationDisabledNotification,
                        level: "success"
                    });
                });
            }).catch((error) => {
                const e: { code: number | undefined, body: Promise<any> | undefined } | null = error as { code: number | undefined, body: Promise<any> | undefined } | null;

                if (e != null && e.code != undefined) {
                    let errorContent: any = e.body;
                    if (errorContent != undefined) {
                        this.postMessage({
                            message: errorContent.message,
                            level: "error"
                        });
                    }
                }
                this.setState({ loading: false });
            });
        });
    }

    validatePhone = (phone: any): boolean => {
        if (!phone) {
            return false;
        }
        let regex = /^\+[0-9]+\.[0-9]+$/;
        return regex.test(phone);
    }

    onActivatePushAuth = () => {
        const { securityActionState, phone } = this.state;
        let activatePushAuthRequest = this.dataManager.getPushAuthenticationEnableResource();
        if (securityActionState === SecuritySettingsActionState.EnforceActivatePushAuth) {
            if (!this.validatePhone(phone)) {
                this.postMessage({
                    message: "Phone number is either empty or incorrect",
                    level: "error"
                });
                return;
            }
            activatePushAuthRequest = this.dataManager.enforceGetPushAuthenticationEnableResourceWithPhone(phone);
        }
        this.setLoading(() => {
            activatePushAuthRequest
                .then((result) => {
                    const { pushAuthApprovalRequestId, pushAuthApprovalCode } = result;
                    this.setState({ pushAuthApprovalRequestId, pushAuthApprovalCode, securityActionState: SecuritySettingsActionState.VerifyPushAuth, loading: false, verifyingPushAuth: !!pushAuthApprovalRequestId }, () => {
                        !!pushAuthApprovalRequestId && setTimeout(() => {
                            (this.props.isEnforced) ? this.onEnforceAndEnablePushAuthentication() : this.onVerifyAndEnablePushAuthentication();
                        }, 2000);
                    });
                }).catch((error) => {
                    const e: { code: number | undefined, body: Promise<any> | undefined } | null = error as { code: number | undefined, body: Promise<any> | undefined } | null;

                    if (e != null && e.code != undefined) {
                        let errorContent: any = e.body;
                        const isPhoneNotLinked: boolean = e.code == SetupToEnablePushAuthenticationStatusCode.MissingPhoneNumber;
                        this.postMessage({
                            message: errorContent.message,
                            level: isPhoneNotLinked ? "warning" : "error"
                        });
                        if (isPhoneNotLinked) {
                            this.setState({ securityActionState: SecuritySettingsActionState.EnforceActivatePushAuth });
                        }
                    }
                    this.setState({ loading: false });
                });
        });
    }

    onVerifyAndEnablePushAuthentication = () => {
        const { pushAuthApprovalRequestId, securityActionState, multiFactorSettings } = this.state;
        let { initialMultiFactorAuthenticationType } = this.state;
        if (!pushAuthApprovalRequestId) {
            return;
        }
        this.setState({ verifyingPushAuth: true }, () => {
            this.dataManager.verifyAndEnablePushAuthentication(pushAuthApprovalRequestId).then((result) => {
                const { backupCodes, status, approvalStatus } = result;
                if (status && approvalStatus === "pending") {
                    (securityActionState == SecuritySettingsActionState.VerifyPushAuth && setTimeout(() => {
                        this.onVerifyAndEnablePushAuthentication();
                    }, 2000));
                    return;
                }
                multiFactorSettings.multiFactorEnabled = true;
                initialMultiFactorAuthenticationType = MultiFactorAuthenticationType.PushAuthentication;
                multiFactorSettings.multiFactorAuthenticationType = MultiFactorAuthenticationType.PushAuthentication;
                this.setState({ backupCodes, securityActionState: SecuritySettingsActionState.VerifiedPushAuth, verifyingPushAuth: false, multiFactorSettings, initialMultiFactorAuthenticationType }, () => {
                    this.postMessage({
                        message: strings.PushAuthEnabledForAccount,
                        level: "success"
                    });
                });
            }).catch((error) => {
                const e: { code: number | undefined, body: Promise<any> | undefined } | null = error as { code: number | undefined, body: Promise<any> | undefined } | null;

                if (e != null && e.code != undefined) {
                    let errorContent: any = e.body;
                    if (errorContent != undefined) {
                        this.postMessage({
                            message: errorContent.message,
                            level: "error"
                        });
                    }
                }
                multiFactorSettings.multiFactorAuthenticationType = initialMultiFactorAuthenticationType;
                this.setState({ loading: false, verifyingPushAuth: false, multiFactorSettings });
            });
        });
    }

    onEnforceAndEnablePushAuthentication = () => {
        const { pushAuthApprovalRequestId, securityActionState, multiFactorSettings } = this.state;
        let { initialMultiFactorAuthenticationType } = this.state;
        const { onMultiFactorSettingEnabled } = this.props;
        if (!pushAuthApprovalRequestId) {
            return;
        }
        this.setState({ verifyingPushAuth: true }, () => {
            this.dataManager.enforceAndEnablePushAuthentication(pushAuthApprovalRequestId).then((result) => {
                const { backupCodes, status, reason, accessToken, renewalToken, approvalStatus } = result;
                if (status && approvalStatus === "pending") {
                    (securityActionState == SecuritySettingsActionState.VerifyPushAuth && setTimeout(() => {
                        this.onEnforceAndEnablePushAuthentication();
                    }, 2000));
                    return;
                }
                multiFactorSettings.multiFactorEnabled = true;
                initialMultiFactorAuthenticationType = MultiFactorAuthenticationType.PushAuthentication;
                multiFactorSettings.multiFactorAuthenticationType = MultiFactorAuthenticationType.PushAuthentication;
                this.setState({ backupCodes, securityActionState: SecuritySettingsActionState.VerifiedPushAuth, verifyingPushAuth: false, multiFactorSettings, initialMultiFactorAuthenticationType }, () => {
                    onMultiFactorSettingEnabled && onMultiFactorSettingEnabled({ status, reason, accessToken, renewalToken });
                    this.postMessage({
                        message: strings.PushAuthEnabledForAccount,
                        level: "success"
                    });
                });
            }).catch((error) => {
                const e: { code: number | undefined, body: Promise<any> | undefined } | null = error as { code: number | undefined, body: Promise<any> | undefined } | null;

                if (e != null && e.code != undefined) {
                    let errorContent: any = e.body;
                    if (errorContent != undefined) {
                        this.postMessage({
                            message: errorContent.message,
                            level: "error"
                        });
                    }
                }
                multiFactorSettings.multiFactorAuthenticationType = initialMultiFactorAuthenticationType;
                this.setState({ loading: false, verifyingPushAuth: false, multiFactorSettings });
            });
        });
    }

    renderInformation = (): JSX.Element => {
        const { userInfo } = this.props;
        const canManageSecurity: boolean = userInfo !== undefined ? ProfileAccessHelper.canManageSecurity(userInfo) : false;
        const options: IChoiceGroupOption[] = [
            { key: MultiFactorAuthenticationType[MultiFactorAuthenticationType.TotpAuthentication], text: 'TOTP Auth', iconProps: { iconName: 'ReminderTime' } },
            { key: MultiFactorAuthenticationType[MultiFactorAuthenticationType.PushAuthentication], text: 'Push Auth', iconProps: { iconName: 'PowerButton' } },
        ]
        const { showDisablePushAuth, showDisableTotpAuth, multiFactorSettings, initialMultiFactorAuthenticationType } = this.state;
        const { multiFactorEnabled, multiFactorAuthenticationType, requireMultiFactor } = multiFactorSettings;
        const isTotpAuthCached = initialMultiFactorAuthenticationType == MultiFactorAuthenticationType.TotpAuthentication;
        const isPushAuthCached = initialMultiFactorAuthenticationType == MultiFactorAuthenticationType.PushAuthentication;

        return <div>
            <ChoiceGroup
                label={strings.SelectMultiFactorOptionLabel}
                disabled={!canManageSecurity}
                selectedKey={MultiFactorAuthenticationType[multiFactorAuthenticationType]}
                options={options}
                onChange={(event, option) => {
                    const { multiFactorSettings } = this.state;
                    option && (multiFactorSettings.multiFactorAuthenticationType = MultiFactorAuthenticationType[option.key])
                    this.setState({ multiFactorSettings });
                }} />
            {
                multiFactorAuthenticationType == MultiFactorAuthenticationType.TotpAuthentication && <MessageBar
                    className={styles.Information}
                    messageBarType={multiFactorEnabled && isTotpAuthCached ? MessageBarType.success : MessageBarType.info}
                    actions={<>
                        {canManageSecurity &&
                            <div>
                                <MessageBarButton disabled={multiFactorEnabled && isTotpAuthCached} onClick={() => this.setState({ securityActionState: SecuritySettingsActionState.ActivateTotpAuth })}>Enable</MessageBarButton>
                                <div className={styles.DisableTotpAuthButton}>
                                    <MessageBarButton disabled={requireMultiFactor || (!multiFactorEnabled || !isTotpAuthCached)} onClick={() => this.setState({ showDisableTotpAuth: true })}>Disable</MessageBarButton>
                                </div>
                                <ConfirmDialog
                                    target={`.${styles.DisableTotpAuthButton}`}
                                    title={strings.DisableTotpAuth}
                                    visible={showDisableTotpAuth}
                                    content={strings.TotpAuthenticationDisableInformation}
                                    onClose={() => this.setState({ showDisableTotpAuth: false })}
                                    confirmText={"Yes"}
                                    declineText={"No"}
                                    onConfirm={this.onDisableMultiFactor}
                                />
                            </div>}</>
                    }
                >
                    <strong>{strings.TotpAuthHeading}</strong>
                    <div>{strings.Enable2FactorInformation}</div>
                </MessageBar>
            }
            {
                multiFactorAuthenticationType == MultiFactorAuthenticationType.PushAuthentication && <MessageBar
                    className={styles.Information}
                    messageBarType={multiFactorEnabled && isPushAuthCached ? MessageBarType.success : MessageBarType.info}
                    actions={
                        <div>
                            <MessageBarButton disabled={multiFactorEnabled && isPushAuthCached} onClick={() => this.setState({ securityActionState: SecuritySettingsActionState.ActivatePushAuth })}>Enable</MessageBarButton>
                            <div className={styles.DisablePushAuthButton}>
                                <MessageBarButton disabled={requireMultiFactor || (!multiFactorEnabled || !isPushAuthCached)} onClick={() => this.setState({ showDisablePushAuth: true })}>Disable</MessageBarButton>
                            </div>
                            <ConfirmDialog
                                target={`.${styles.DisablePushAuthButton}`}
                                title={strings.DisablePushAuth}
                                visible={showDisablePushAuth}
                                content={strings.PushAuthDisableInformation}
                                onClose={() => this.setState({ showDisablePushAuth: false })}
                                confirmText={"Yes"}
                                declineText={"No"}
                                onConfirm={this.onDisableMultiFactor}
                            />
                        </div>
                    }>
                    <strong>{strings.PushAuthHeading}</strong>
                    <div>{strings.EnablePushAuthInformation}</div>
                </MessageBar>
            }
        </div>
    }

    renderActivateTotpAuth = (): JSX.Element => {
        const { showActivateTotpAuth } = this.state;
        return <div className={styles.Container}>
            <div className={styles.Heading}>{strings.TotpAuthHeading}</div>
            <div className={styles.Logo}>
                <Icon iconName="AzureKeyVault" />
            </div>

            <div className={styles.Heading}>
                <div className={styles.AlignCenter}>
                    {strings.EnableTotpAuthHeading}
                </div>
            </div>
            <div className={styles.SubHeading}>
                <div className={styles.AlignCenter}>
                    {strings.EnableTotpAuthSubHeading}
                </div>
            </div>
            <div className={styles.Content}>
                {strings.TotpInfo}
            </div>
            <div className={styles.Content}>
                <div>{strings.TotpAuthAppsInfoDescription}</div>
                <div><a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">{strings.GoogleAuthenticator}</a>&nbsp;<span>{strings.GoogleAuthenticatorDevices}</span></div>
                <div><a href="">{strings.DuoMobile}</a>&nbsp;<span>{strings.DuoMobileDevices}</span></div>
                <div><a href="https://play.google.com/store/apps/details?id=com.azure.authenticator">{strings.Authenticator}</a>&nbsp;<span>{strings.AuthenticatorDevices}</span></div>
            </div>
            <div className={styles.Content}>
                <div className={styles.Important}>
                    <div className={styles.AlignCenter}>
                        {strings.Important}
                    </div>
                    <div>
                        {strings.TotpAuthenticationNote}
                    </div>
                </div>
            </div>
            <div className={styles.AlignCenter}>
                <div className={styles.ActivateTotpAuth}>
                    <DefaultButton disabled={this.state.loading} className={styles.Button} onClick={() => this.setState({ showActivateTotpAuth: true })}>{strings.Activate}</DefaultButton>
                </div>
                <ConfirmDialog
                    target={`.${styles.ActivateTotpAuth}`}
                    title={strings.ActivateTotpAuthConfirmationTitle}
                    visible={showActivateTotpAuth}
                    content={strings.ActivateTotpAuthConfirmation}
                    onClose={() => this.setState({ showActivateTotpAuth: false })}
                    confirmText={"Proceed"}
                    declineText={"Cancel"}
                    onConfirm={this.onActivateTotpAuthenticationActivate}
                />
            </div>
            <div className={styles.AlignRight}>
                <div>
                    <a role="button" href="" onClick={(event) => this.onBackClick(event, SecuritySettingsActionState.Information)}>{strings.Back}</a>
                </div>
            </div>
        </div>;
    }

    renderBackupCodes = (): JSX.Element => {
        let { backupCodes } = this.state;
        if (!backupCodes) {
            backupCodes = [];
        }
        let totalNode = backupCodes.length;
        let nodes: JSX.Element[] = [];
        for (let i = 0; i < totalNode; i = i + 2) {
            let node = <div key={("container" + i)} className={styles.AlignCenter}>
                <span key={i} className={styles.BackupCode}><pre>{backupCodes[i]}</pre></span>
                <span key={(i + 1)} className={styles.BackupCode}><pre>{backupCodes[i + 1]}</pre></span>
            </div>;
            nodes.push(node);
        }
        return <div className={styles.Container}>
            <div className={styles.Content}>
                <div className={styles.Important}>
                    {strings.BackupCodesInformation}
                </div>
            </div>
            {nodes}
        </div>;
    }

    renderVerifyTotpAuth = (): JSX.Element => {
        const { isEnforced } = this.props;
        const { sharedAuthKey, qrCodeContent, verificationCode, securityActionState } = this.state;
        const decodedQRCodeContent = decodeURIComponent(qrCodeContent);
        if (securityActionState == SecuritySettingsActionState.VerifiedTotpAuth) {
            return <div>
                {this.renderBackupCodes()}
                {
                    // If Enforced don't show back button.
                    !isEnforced && <div className={styles.AlignRight}>
                        <div>
                            <a role="button" href="" onClick={(event) => this.onBackClick(event, SecuritySettingsActionState.Information)}>{strings.Back}</a>
                        </div>
                    </div>
                }
            </div>
        }
        return <div className={styles.Container}>
            <div className={styles.Heading}>{strings.TotpAuthHeading}</div>
            <div className={styles.Important}>
                <div className={styles.AlignCenter}>
                    {strings.Important}
                </div>
                <div className={styles.Content}>
                    {strings.TotpAuthenticationNote}
                </div>
            </div>
            <div className={styles.Content}>
                <div className={styles.AlignCenter}>
                    {strings.ConfigureTotpAuthContent}
                </div>
                <div className={styles.AlignCenter}>
                    <strong>{sharedAuthKey}</strong>
                </div>
                <div className={styles.AlignCenter}>
                    <strong>{strings.Or}</strong>
                </div>
                <div className={styles.AlignCenter}>
                    <QRCodeComponent content={decodedQRCodeContent} />
                </div>
                <div>{strings.CompleteTotpAuthDescription}</div>
                <div className={styles.AlignCenter}>
                    <TextField
                        styles={{
                            root: {
                                width: '200px'
                            }
                        }}
                        label={undefined}
                        value={verificationCode}
                        name="verificationCode"
                        placeholder="eg. 123456"
                        onGetErrorMessage={(value) => value ? "" : strings.VerificationCodeValidationMessage}
                        validateOnFocusOut={true}
                        validateOnLoad={false}
                        onChange={(event, value) => this.setState({ verificationCode: value as any })}
                    />
                </div>
                <div className={styles.AlignCenter}>
                    <DefaultButton disabled={this.state.loading} className={styles.Button}
                        onClick={(isEnforced) ? this.onEnforceAndEnableTotpAuthentication : this.onVerifyAndEnableTotpAuthentication}>{strings.VerifyAndSave}</DefaultButton>
                </div>
            </div>
            <div className={styles.AlignRight}>
                <div>
                    <a role="button" href="" onClick={(event) => this.onBackClick(event, SecuritySettingsActionState.ActivateTotpAuth)}>{strings.Back}</a>
                </div>
            </div>
        </div>;
    }

    renderDisableTotpAuth = (): JSX.Element => {
        return <div className={styles.Container}>
            <div className={styles.Heading}>{strings.TotpAuthHeading}</div>
            <div className={styles.Important}>{strings.TotpAuthenticationDisableInformation}</div>
            <div className={styles.AlignCenter}>
                <DefaultButton disabled={this.state.loading} className={styles.Button} onClick={this.onDisableMultiFactor}>{strings.Disable}</DefaultButton>
            </div>
            <div className={styles.AlignRight}>
                <div>
                    <a role="button" href="" onClick={(event) => this.onBackClick(event, SecuritySettingsActionState.Information)}>{strings.Back}</a>
                </div>
            </div>
        </div>;
    }

    renderActivatePushAuth = (): JSX.Element => {
        const { securityActionState, phone, showActivatePushAuth } = this.state;
        const isEnforceActivatePushAuth: boolean = securityActionState == SecuritySettingsActionState.EnforceActivatePushAuth;
        return <div className={styles.Container}>
            <div className={styles.Heading}>{strings.PushAuthHeading}</div>
            <div className={styles.Logo}>
                <Icon iconName="AzureKeyVault" />
            </div>

            <div className={styles.Heading}>
                <div className={styles.AlignCenter}>
                    {strings.EnablePushAuthHeading}
                </div>
            </div>
            <div className={styles.SubHeading}>
                <div className={styles.AlignCenter}>
                    {strings.EnablePushAuthSubHeading}
                </div>
            </div>
            <div className={styles.Content}>
                {strings.EnablePushAuthInformation}
            </div>
            {isEnforceActivatePushAuth &&
                <React.Fragment>
                    <div className={styles.AlignCenter}>
                        <span>
                            <strong>
                                {strings.PhoneNumNotLinkedDescription}
                            </strong>
                        </span>
                    </div>
                    <div className={styles.AlignCenter}>
                        <TextField
                            styles={{
                                root: {
                                    width: '200px'
                                }
                            }}
                            placeholder={strings.PhoneNumLabel}
                            value={phone}
                            name="phone"
                            onChange={(ev, phone) => {
                                this.setState({ phone });
                            }}
                            onGetErrorMessage={(value): string => {
                                return this.validatePhone(value)
                                    ? ""
                                    : "Phone number must be in format +46.123456789";
                            }}
                            required={true}
                            validateOnLoad={false}
                            validateOnFocusOut={true}
                        />
                    </div>
                </React.Fragment>
            }
            <div className={styles.AlignCenter}>
                <div className={styles.ActivatePushAuth}>
                    <DefaultButton disabled={this.state.loading} className={styles.Button} onClick={isEnforceActivatePushAuth ? this.onActivatePushAuth : () => this.setState({ showActivatePushAuth: true })}>{strings.Activate}</DefaultButton>
                </div>
                <ConfirmDialog
                    target={`.${styles.ActivatePushAuth}`}
                    title={strings.ActivatePushAuthConfirmationTitle}
                    visible={showActivatePushAuth}
                    content={strings.ActivatePushAuthConfirmation}
                    onClose={() => this.setState({ showActivatePushAuth: false })}
                    confirmText={"Proceed"}
                    declineText={"Cancel"}
                    onConfirm={this.onActivatePushAuth}
                />
            </div>
            <div className={styles.AlignRight}>
                <div>
                    <a role="button" href="" onClick={(event) => this.onBackClick(event, SecuritySettingsActionState.Information)}>{strings.Back}</a>
                </div>
            </div>
        </div>;
    }

    renderVerifyPushAuth = (): JSX.Element => {
        const { securityActionState, verifyingPushAuth, loading, pushAuthApprovalCode } = this.state;
        const { isEnforced } = this.props;

        if (securityActionState == SecuritySettingsActionState.VerifiedPushAuth) {
            return <div>
                {this.renderBackupCodes()}
                {!isEnforced &&
                    <div className={styles.AlignRight}>
                        <div>
                            <a role="button" href="" onClick={(event) => this.onBackClick(event, SecuritySettingsActionState.Information)}>{strings.Back}</a>
                        </div>
                    </div>
                }
            </div>
        }

        return <div className={styles.Container}>
            <div className={styles.Heading}>{strings.PushAuthHeading}</div>
            {
                securityActionState == SecuritySettingsActionState.VerifyPushAuth &&
                <React.Fragment>
                    <div>{strings.PushAuthEnableRequestSentToApprove}</div>
                    {verifyingPushAuth && <ProgressIndicator label={<div>Verify code: <strong>{pushAuthApprovalCode}</strong> in authy app</div>} description="Checking status" />}
                    <div className={styles.AlignCenter}>
                        <DefaultButton disabled={loading || verifyingPushAuth} className={styles.Button} onClick={this.onActivatePushAuth}>Re-Send Approval Request</DefaultButton>
                    </div>
                    <div className={styles.AlignRight}>
                        <div>
                            <a role="button" href="" onClick={(event) => this.onBackClick(event, SecuritySettingsActionState.ActivatePushAuth)}>{strings.Back}</a>
                        </div>
                    </div>
                </React.Fragment>
            }
        </div>
    }

    renderDiablePushAuth = (): JSX.Element => {
        return <div className={styles.Container}>
            <div className={styles.Heading}>{strings.PushAuthHeading}</div>
            <div className={styles.Important}>Are you sure you want to disable push authentication?</div>
            <div className={styles.AlignCenter}>
                <DefaultButton disabled={this.state.loading} className={styles.Button} onClick={this.onDisableMultiFactor}>{strings.Disable}</DefaultButton>
            </div>
            <div className={styles.AlignRight}>
                <div>
                    <a role="button" href="" onClick={(event) => this.onBackClick(event, SecuritySettingsActionState.ActivatePushAuth)}>{strings.Back}</a>
                </div>
            </div>
        </div>;
    }

    renderBody = (): JSX.Element => {
        const { securityActionState } = this.state;
        if (securityActionState == SecuritySettingsActionState.Information) {
            return this.renderInformation();
        } else if (securityActionState == SecuritySettingsActionState.ActivateTotpAuth) {
            return this.renderActivateTotpAuth();
        } else if (securityActionState == SecuritySettingsActionState.VerifyTotpAuth || securityActionState == SecuritySettingsActionState.VerifiedTotpAuth) {
            return this.renderVerifyTotpAuth();
        } else if (securityActionState == SecuritySettingsActionState.ActivatePushAuth || securityActionState == SecuritySettingsActionState.EnforceActivatePushAuth) {
            return this.renderActivatePushAuth();
        } else if (securityActionState == SecuritySettingsActionState.VerifyPushAuth || securityActionState == SecuritySettingsActionState.VerifiedPushAuth) {
            return this.renderVerifyPushAuth();
        } else {
            return this.renderInformation();
        }
    }

    notifier: Notifier = null as any;

    render() {
        const { loading } = this.state;
        return <div className="Profile">
            {loading && <ProgressIndicator ariaValueText={"Loading"} />}
            <Notifier ref={r => this.notifier = r as any} />
            {this.renderBody()}
        </div>;
    }
}

ManageSecurity.defaultProps = {
    isEnforced: false
};