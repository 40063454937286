import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import { Label } from "@fluentui/react/lib/Label";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { Modal } from "@fluentui/react/lib/Modal";
import { ProgressIndicator } from "@fluentui/react/lib/ProgressIndicator";
import { TextField } from "@fluentui/react/lib/TextField";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { Loader } from "../Common/Loader/Loader";
import { LoaderType } from "../Common/Loader/LoaderType";
import { ForgotPassword } from "./ForgotPassword/ForgotPassword";
import { Constants } from "./Constants";
import { ForgotMultiFactor } from "./ForgotPassword/ForgotMultiFactor/ForgotMultiFactor";
import styles from "./Login.module.scss";
import { strings } from "./strings";
import { ILoginProps } from "./ILoginProps";
import { ILoginState } from "./ILoginState";
import { AuthenticationState } from "../../models/AuthenticateState";
import { IPasswordAuthResponse } from "../../models/IPasswordAuthResponse";
import { IPushAuthMetadata } from "../../models/IPushAuthMetadata";
import { ILoginMetadata } from "../../models/ILoginMetadata";
import { LoginStep } from "../../models/LoginStep";
import ConnectionContext from "../../utils/ConnectionContext";
import { PasswordStrengthTypeEnum } from '../../models/PasswordStrengthTypeEnum';
import PasswordStrengthHelper from "../Common/PasswordStrength/PasswordStrengthHelper";

export default class Login extends React.Component<ILoginProps, ILoginState> {
    constructor(props: ILoginProps) {
        super(props);
        this.state = {
            login: {
                username: "",
                password: "",
                rememberMe: false,
                isRecoveryCode: false,
                code: ""
            },
            currentStep: LoginStep.ShowUsername,
            forceMfa: false,
            authenticationState: AuthenticationState.NoAction,
            loginSuccess: false,
            loading: false,
            approvalRequestId: "",
            approvalCode: "",
            hasError: false,
            userPath: "",
            showForgetPassword: false,
            showForgetMultiFactor: false
        };
    }

    public async componentDidMount() {
        const { location } = this.props.routeProps;
        if (location !== undefined && location.state !== undefined) {
            localStorage.setItem(Constants.Tokens.RedirectUrl, location.state.referrer);
        }

        this.setState((prevState: ILoginState): ILoginState => {
            const { login } = prevState;
            const rememberMe: boolean = this.isDeviceTokenSet;
            login.rememberMe = rememberMe;
            prevState.login = login;
            return prevState;
        });

        const federationId = ConnectionContext.getQueryStringByParameter("federation");
        if (federationId != undefined) {
            await this.handleFederationAuthentication(federationId);
        }
    }

    private get showUsername(): boolean {
        const { currentStep } = this.state;
        return currentStep == LoginStep.ShowUsername;
    }

    private get showPassword(): boolean {
        const { currentStep } = this.state;
        return currentStep == LoginStep.ShowPassword;
    }

    private get showVerificationCode(): boolean {
        const { currentStep } = this.state;
        return currentStep == LoginStep.ShowVerificationCode;
    }

    private get showRecoveryCode(): boolean {
        const { currentStep } = this.state;
        return currentStep == LoginStep.ShowRecoveryCode;
    }

    private get showPushApproval(): boolean {
        const { currentStep } = this.state;
        return currentStep == LoginStep.ShowPushApproval;
    }

    private get showBackButton(): boolean {
        return !this.showUsername;
    }

    private get showForgotPasswordLink() {
        const { currentStep } = this.state;
        return currentStep == LoginStep.ShowUsername || currentStep == LoginStep.ShowPassword;
    }

    private get isDeviceTokenSet(): boolean {
        let isDeviceTokenSet = localStorage.getItem(Constants.Tokens.Device);
        return isDeviceTokenSet != null && isDeviceTokenSet.toLowerCase() == "true";
    }

    private get loginRequestMessage(): ILoginMetadata {
        return { ...this.state.login };
    }

    private get pushLoginRequestMessage(): IPushAuthMetadata {
        const {
            approvalRequestId,
            login: { rememberMe }
        } = this.state;
        return {
            approvalRequestId,
            rememberMe
        };
    }

    public render(): React.ReactNode {
        const {
            loginSuccess,
            showForgetPassword,
            login,
            showForgetMultiFactor,
            forceMfa,
            approvalCode,
            approvalRequestId,
            recoveryCodeContext,
            loading
        } = this.state;

        const { username, password, code, rememberMe } = login;

        if (loading) {
            return <Loader type={LoaderType.Fallback} />
        }

        if (loginSuccess) {
            const userPath: any = localStorage.getItem(Constants.Tokens.RedirectUrl);

            if (forceMfa) {
                return <Redirect to={{ pathname: "/enablemfa", state: { forceMfa } }} />;
            }

            if (userPath != null && userPath !== "/login" && userPath !== "/enablemfa") {
                localStorage.removeItem(Constants.Tokens.RedirectUrl);
                return <Redirect to={userPath} />;
            }

            return <Redirect to="/dashboard" />;
        }

        return (
            <div className={styles.container}>
                <div className={styles.page}>
                    <div className={styles.header}>
                        <div className={styles.content}></div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.content}>
                            <div className={styles.form}>
                                <div className={styles.formName}>{strings.Login}</div>
                                <form onSubmit={this.handleLogin}>
                                    {this.showUsername ? (
                                        <TextField
                                            autoComplete="off"
                                            name={"username"}
                                            aria-label={strings.Username}
                                            value={username}
                                            className={styles.formControl}
                                            placeholder={strings.Username}
                                            onChange={this.handleValueChange}
                                        />
                                    ) : (
                                        <Label>
                                            {strings.LoggingInAs}
                                            <strong>{username}</strong>
                                        </Label>
                                    )}
                                    {this.showPassword && (
                                        <React.Fragment>
                                            <TextField
                                                autoComplete="off"
                                                name={"password"}
                                                aria-label={strings.Password}
                                                value={password}
                                                className={styles.formControl}
                                                type="password"
                                                placeholder={strings.Password}
                                                onChange={this.handleValueChange}
                                            />
                                        </React.Fragment>
                                    )}
                                    {this.showRecoveryCode && (
                                        <React.Fragment>
                                            <Label>{strings.MultiFactorVerification}</Label>
                                            <div className={styles.formControl}>{strings.BackupCodeLabel}</div>
                                            <TextField
                                                name={"code"}
                                                aria-label={strings.RecoveryCode}
                                                type="password"
                                                value={code}
                                                className={styles.formControl}
                                                placeholder={strings.RecoveryCode}
                                                onChange={this.handleValueChange}
                                            />
                                        </React.Fragment>
                                    )}
                                    {this.showVerificationCode && (
                                        <React.Fragment>
                                            <Label>{strings.MultiFactorVerification}</Label>
                                            <div className={styles.formControl}>{strings.VerificationCodeLabel}</div>
                                            <TextField
                                                name={"code"}
                                                aria-label={strings.VerificationCode}
                                                type="password"
                                                value={code}
                                                className={styles.formControl}
                                                placeholder={strings.VerificationCode}
                                                onChange={this.handleValueChange}
                                            />
                                            <div className={styles.formControl}>
                                                <strong>Note:</strong>
                                                {strings.RememberMeNote}
                                            </div>
                                            <Checkbox
                                                className={styles.formControl}
                                                name="rememberMe"
                                                label={strings.RememberMe}
                                                checked={rememberMe}
                                                onChange={this.handleValueChange}
                                            />
                                        </React.Fragment>
                                    )}
                                    {this.showPushApproval && (
                                        <React.Fragment>
                                            <Label style={{ fontStyle: "italic" }}>{strings.MultiFactorVerification}</Label>
                                            {approvalRequestId && (
                                                <ProgressIndicator
                                                    className={styles.formControl}
                                                    label={
                                                        <div>
                                                            Verify code: <strong>{approvalCode}</strong> in authy app
                                                        </div>
                                                    }
                                                    description={strings.CheckingStatus}
                                                />
                                            )}
                                            <div className={styles.formControl}>
                                                <strong>Note:</strong>
                                                {strings.RememberMeNote}
                                            </div>
                                            <Checkbox
                                                className={styles.formControl}
                                                name="rememberMe"
                                                label={strings.RememberMe}
                                                checked={rememberMe}
                                                onChange={this.handleValueChange}
                                            />
                                        </React.Fragment>
                                    )}
                                    <div className={styles.formLinks}>
                                        {this.showForgotPasswordLink && (
                                            <a className={styles.link} href="" onClick={this.onClickForgetPassword}>
                                                {strings.ForgotPassword}
                                            </a>
                                        )}
                                        {(this.showVerificationCode || this.showPushApproval) && (
                                            <React.Fragment>
                                                <a className={styles.link} href="" onClick={this.onClickForgotMultiFactor}>
                                                    {strings.LostMultiFactorAppAccess}
                                                </a>
                                            </React.Fragment>
                                        )}
                                        {(this.showVerificationCode || this.showPushApproval || this.showRecoveryCode) && (
                                            <React.Fragment>
                                                <a className={styles.link} href="" onClick={this.onRecoveryCodeClick}>
                                                    {(this.showVerificationCode && strings.TryAnotherWayToLogin) ||
                                                        (this.showPushApproval && strings.TryAnotherWayToLogin) ||
                                                        (this.showRecoveryCode &&
                                                            recoveryCodeContext === LoginStep.ShowPushApproval &&
                                                            strings.UseAuthyApp) ||
                                                        (this.showRecoveryCode &&
                                                            recoveryCodeContext === LoginStep.ShowVerificationCode &&
                                                            strings.UseAuthenticator)}
                                                </a>
                                            </React.Fragment>
                                        )}
                                    </div>
                                    <div className={styles.formActions}>
                                        {this.showBackButton ? (
                                            <DefaultButton onClick={this.handleBackClick}>{strings.Back}</DefaultButton>
                                        ) : (
                                            <span></span>
                                        )}
                                        <PrimaryButton type="submit">{strings.Login}</PrimaryButton>
                                    </div>
                                    {this.renderError()}
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.content}>
                            Not Registered? <a href="">Ask for an account</a>
                        </div>
                    </div>
                </div>
                <div className={styles.introduction}>
                    <div className={styles.header}>
                        <div className={styles.content}>
                            <div>
                                TO <strong>MANAGE</strong>, <strong>CONTROL</strong>
                            </div>
                            <div>
                                AND GET AN <strong>OVERVIEW</strong>
                            </div>
                            <div>OF YOUR IP PORTFOLIO</div>
                        </div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.content}></div>
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.content}></div>
                    </div>
                </div>
                <Modal className="Modal" isOpen={showForgetPassword} onDismiss={this.onCloseForgetPassword}>
                    <ForgotPassword email={login.username} onClose={this.onCloseForgetPassword} connectionContext={this.props.connectionContext} />
                </Modal>
                <Modal className="Modal" isOpen={showForgetMultiFactor} onDismiss={this.onCloseForgetMultiFactor}>
                    <ForgotMultiFactor onClose={this.onCloseForgetMultiFactor} />
                </Modal>
            </div>
        );
    }

    private updateIsDeviceTokenSet = (isDeviceTokenSet: boolean): void => {
        localStorage.setItem(Constants.Tokens.Device, isDeviceTokenSet.toString());
    }

    private handleFederationAuthentication = async (federationId: string | string[]): Promise<void> => {
        this.setLoading(true);
        try {
            await this.props.connectionContext.get(`/authentication/${federationId}/challenge/token`);

            this.setState((prevState: ILoginState): ILoginState => {
                prevState.loginSuccess = true;
                return prevState;
            });
        }
        catch (e) {
            this.setState((prevState: ILoginState): ILoginState => {
                prevState.hasError = true;
                return prevState;
            });
        }
        this.setLoading(false);
    }

    private onPasswordAuthenticated = (authResponse: IPasswordAuthResponse) => {
        const { authenticationState } = authResponse;
        this.updateIsDeviceTokenSet(authResponse.setDeviceToken);
        let enforceMultiFactorAuthentication = authenticationState == AuthenticationState.EnforceMultiFactor;
        this.setState((prevState: ILoginState): ILoginState => {
            prevState.forceMfa = enforceMultiFactorAuthentication;
            prevState.loginSuccess = true;
            return prevState;
        });
    }

    private handleBackClick = (): void => {
        let { login, currentStep } = this.state;

        switch (currentStep) {
            case LoginStep.ShowPassword:
                login.password = "";
                currentStep = LoginStep.ShowUsername;
                break;
            case LoginStep.ShowPushApproval:
                currentStep = LoginStep.ShowPassword;
                break;
            case LoginStep.ShowVerificationCode:
                login.code = "";
                currentStep = LoginStep.ShowPassword;
                break;
            case LoginStep.ShowRecoveryCode:
                login.code = "";
                login.isRecoveryCode = false;
                currentStep = LoginStep.ShowPassword;
                break;
            case LoginStep.ShowUsername:
            default:
                break;
        }

        this.setState((prevState: ILoginState): ILoginState => {
            prevState.login = login;
            prevState.currentStep = currentStep;
            prevState.hasError = false;
            return prevState;
        });
    }

    private handlePushNotificationlogin = async (): Promise<void> => {
        try {
            const data: IPasswordAuthResponse = await this.props.connectionContext.post(
                `/authentication/00000000-0000-0000-0000-000000000000/approve`,
                this.pushLoginRequestMessage
            );

            const { authenticationState, approvalRequestId } = data;

            let pollForPushAuth = authenticationState == AuthenticationState.PollForPushAuth;

            if (approvalRequestId == this.state.approvalRequestId && pollForPushAuth) {
                setTimeout(() => {
                    this.showPushApproval && this.handlePushNotificationlogin();
                }, 2000);
                return;
            }

            this.onPasswordAuthenticated(data);
        } catch (ex) {
            this.setState((prevState: ILoginState): ILoginState => {
                prevState.hasError = true;
                prevState.approvalRequestId = "";
                prevState.approvalCode = "";
                return prevState;
            });
        }
    }

    private handleValueChange = (event, value): void => {
        const { login } = this.state;
        const prop = event && event.target.name;
        login[prop] = value;

        this.setState((prevState: ILoginState): ILoginState => {
            prevState.login = login;
            prevState.hasError = false;
            prevState.accountLockedMessage = undefined;
            return prevState;
        });
    };

    private handleLogin = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event && event.preventDefault();
        const { username }: ILoginMetadata = this.state.login;
        const usernameIsEmpty = !username;

        try {
            if (this.showUsername) {
                if (usernameIsEmpty) {
                    this.setState((prevState: ILoginState): ILoginState => {
                        prevState.hasError = true;
                        return prevState;
                    });
                    return;
                }

                let authenticationProviderData = await this.props.connectionContext.get(
                    `/authentication/?username=${username}`
                );

                if (authenticationProviderData.length == 1) {
                    let authenticationProvider = authenticationProviderData[0];
                    if (
                        authenticationProvider.id !== null &&
                        authenticationProvider.id != "00000000-0000-0000-0000-000000000000"
                    ) {
                        window.location.href = `/api/authentication/${authenticationProvider.id}/challenge`;
                        return;
                    }
                }

                //provider is local password, show password input
                this.setState((prevState: ILoginState): ILoginState => {
                    prevState.currentStep = LoginStep.ShowPassword;
                    return prevState;
                });
                return;
            }

            const passwordAuthResponse: IPasswordAuthResponse = await this.props.connectionContext.post(
                `/authentication/00000000-0000-0000-0000-000000000000/password`,
                this.loginRequestMessage
            );
            const { authenticationState, approvalRequestId, approvalCode } = passwordAuthResponse;

            // If multifactor is enabled show verification input.
            if (authenticationState === AuthenticationState.RequireVerificationCode) {
                this.setState((prevState: ILoginState): ILoginState => {
                    prevState.currentStep = LoginStep.ShowVerificationCode;
                    return prevState;
                });
                return;
            }
            else if (authenticationState === AuthenticationState.RequirePushApproval) {
                approvalRequestId &&
                    this.setState((prevState: ILoginState): ILoginState => {
                        prevState.currentStep = LoginStep.ShowPushApproval;
                        prevState.approvalCode = approvalCode;
                        prevState.approvalRequestId = approvalRequestId;
                        return prevState;
                    }, () => {
                        setTimeout(() => {
                            this.handlePushNotificationlogin();
                        }, 2000);
                    });
                return;
            }

            if (passwordAuthResponse != null) {
                this.getAndUpdatePasswordStrength(this.loginRequestMessage.password);
                this.onPasswordAuthenticated(passwordAuthResponse);
            }
        } catch (error) {
            let accountLockedMessage = "";
            const e: { code: number | undefined, body: Promise<any> | undefined } | null = error as { code: number | undefined, body: Promise<any> | undefined } | null;

            if (e != null && e.code != undefined && e.code == 423) {
                const errorContent: { success: boolean, message: string } = await e.body;
                accountLockedMessage = errorContent.message;
            }

            this.setState((prevState: ILoginState): ILoginState => {
                prevState.hasError = true;
                prevState.approvalRequestId = "";
                prevState.accountLockedMessage = accountLockedMessage;
                return prevState;
            });
        }
    }

    renderError = () => {
        const { hasError, currentStep, accountLockedMessage } = this.state;
        let message = "Error logging in.";

        switch (currentStep) {
            case LoginStep.ShowUsername:
                if (accountLockedMessage) {
                    message = accountLockedMessage;
                }
                break;
            case LoginStep.ShowPassword:
                if (accountLockedMessage) {
                    message = accountLockedMessage;
                } else {
                    message = strings.InCorrectNameOrPassword;
                }
                break;
            case LoginStep.ShowRecoveryCode:
                message = strings.UsedOrInvalidRecoveryCode;
                break;
            case LoginStep.ShowVerificationCode:
                message = strings.InCorrectCodeOrExpireCode;
                break;
            case LoginStep.ShowPushApproval:
                message = strings.DenyOrExpiredApprovalRequest;
                break;
            default:
                break;
        }

        return (
            !hasError ? undefined : (
                <MessageBar className={styles.formControl} messageBarType={MessageBarType.error} isMultiline={true}>
                    {message}
                </MessageBar>
            )
        );
    }

    private onRecoveryCodeClick = (event): void => {
        event && event.preventDefault && event.preventDefault();
        this.setState((prevState: ILoginState): ILoginState => {
            let { currentStep, recoveryCodeContext, approvalRequestId, approvalCode, login } = prevState;
            let step = currentStep;
            if (step === LoginStep.ShowPushApproval) {
                approvalRequestId = "";
                approvalCode = "";
            }
            currentStep = recoveryCodeContext && recoveryCodeContext !== currentStep ? recoveryCodeContext : LoginStep.ShowRecoveryCode;
            recoveryCodeContext = step;
            login.isRecoveryCode = currentStep == LoginStep.ShowRecoveryCode;
            prevState.login = { ...login };
            prevState.currentStep = currentStep;
            prevState.recoveryCodeContext = recoveryCodeContext;
            prevState.approvalRequestId = approvalRequestId;
            prevState.approvalCode = approvalCode;
            return prevState;
        });
    }

    private onCloseForgetPassword = (): void => {
        this.setState((prevState: ILoginState): ILoginState => {
            prevState.showForgetPassword = false;
            return prevState;
        });
    }

    private onCloseForgetMultiFactor = (): void => {
        this.setState((prevState: ILoginState): ILoginState => {
            prevState.showForgetMultiFactor = false;
            return prevState;
        });
    }

    private onClickForgotMultiFactor = (event?: any): void => {
        event && event.preventDefault && event.preventDefault();
        this.setState((prevState: ILoginState): ILoginState => {
            prevState.showForgetMultiFactor = true;
            return prevState;
        });
    }

    private onClickForgetPassword = (event?: any): void => {
        event && event.preventDefault && event.preventDefault();
        this.setState((prevState: ILoginState): ILoginState => {
            prevState.showForgetPassword = true;
            return prevState;
        });
    }

    private setLoading = (isLoading: boolean): void => {
        this.setState((prevState: ILoginState): ILoginState => {
            prevState.loading = isLoading;
            return prevState;
        });
    }

    private getAndUpdatePasswordStrength = (password: string) => {
        const passwordStrength: PasswordStrengthTypeEnum = PasswordStrengthHelper.getPasswordStrength(password);
        localStorage.setItem(Constants.User.PasswordStrength, passwordStrength.toString());
    }
}
