import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import * as React from "react";

export interface INotifierState {
    nodes: JSX.Element[];
}

export interface IMessageState {
    message: string | JSX.Element,
    level: "info" | "warning" | "blocked" | "severeWarning" | "success" | "error",
    isPersistant?: boolean,
    isClosable?: boolean
}

export class Notifier extends React.Component<any, INotifierState> {
    constructor(props) {
        super(props);
        this.state = {
            nodes: []
        }
    }

    postMessage = ({message, level, isPersistant = false, isClosable = true}: IMessageState) => {
        let { nodes } = this.state;
        const length = nodes.length;

        const type: MessageBarType = MessageBarType[level];

        let node = React.createElement(MessageBar,
            {  
                key: "Node" + Math.random(),
                messageBarType: type,
                styles: { root: { margin: "5px 0px" } },
                isMultiline: true,
                onDismiss: !isClosable ? undefined : (event) => {
                    let {nodes} = this.state;
                    nodes = nodes.filter(u => u != node);
                    this.setState({nodes})
                },
                dismissButtonAriaLabel:"Close"
            }, message);

        nodes.push(node);

        this.setState({nodes}, () => {
            if (!isPersistant) {
                setTimeout(() => {
                    let {nodes} = this.state;
                    nodes = nodes.filter(u => u != node);
                    this.setState({nodes})
                }, 5000);
            }
        });

    }

    clearMessages = async () => {
        let { nodes } = this.state;
        nodes = [];
        this.setState({nodes});
    }

    render() {
        const {nodes} = this.state;
        return <div>
            {nodes}
        </div>
    }
}