import { isArray } from "util";
import ConnectionContext from "../../utils/ConnectionContext";

export class ProfileDataManager {
    private connectionContext: ConnectionContext;
    constructor() {
        this.connectionContext = new ConnectionContext(window.location.origin + "/api");
    }

    getSecuritySettings() {
        return this.connectionContext.get("/managemfa") as any;
    }

    resetPassword(data) {
        return this.connectionContext.post("/managepassword/user/reset", data);
    }

    resetPasswordUsingLink(data: any, token?: string | string[]) {
        if (token != undefined && !isArray(token)) {
            const headers: any = {
                'reset_token': encodeURIComponent(token)
            };
            return this.connectionContext.post("/managepassword/reset", data, { headers: headers })
        }
    }

    getProfile() {
        return this.connectionContext.get("/profile");
    }

    updateProfile(data) {
        return this.connectionContext.post("/profile", data);
    }

    getTotpEnableResource() {
        return this.connectionContext.post(`/totp`);
    }

    verifyAndEnableTotpAuthentication(verificationCode: string) {
        return this.connectionContext.put(`/totp`, { verificationCode });
    }

    enforceAndEnableTotpAuthentication(verificationCode: string) {
        return this.connectionContext.put(`/totp/enforce`, { verificationCode });
    }

    getPushAuthenticationEnableResource() {
        return this.connectionContext.post(`/pushnotification`);
    }

    enforceGetPushAuthenticationEnableResourceWithPhone(phone) {
        return this.connectionContext.post(`/pushnotification/enforce`, { phone });
    }

    verifyAndEnablePushAuthentication(pushAuthApprovalRequestId: string) {
        return this.connectionContext.put(`/pushnotification`, { pushAuthApprovalRequestId });
    }

    enforceAndEnablePushAuthentication(pushAuthApprovalRequestId: string) {
        return this.connectionContext.put(`/pushnotification/enforce`, { pushAuthApprovalRequestId });
    }

    disableMultiFactorAuthentication() {
        return this.connectionContext.delete(`/managemfa`);
    }

    validatePasswordResetToken(token?: string | string[]) {
        if (token != undefined && !isArray(token)) {
            const headers: any = {
                'reset_token': encodeURIComponent(token)
            };
            return this.connectionContext.get(`/managepassword/validatetoken`, { headers: headers })
        }
    }
}