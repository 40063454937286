export default class ForgotPasswordResource {
    public static errorMessages = {
        invalidEmailErrorMessage: 'Please enter the valid Email.',
        resetLinkErrorMessage:'Unexpected error occured while getting password reset link, please try again'
    }

    public static labels = {
        backToLogin: 'Back to Login',
        email: 'Email',
        buttons: {
            cancel: 'Cancel',
            submit: 'Submit'
        }
    }

    public static informations = {
        recoverYourPassword: 'Recover your password',
        forgotPasswordEmailResetDescripton: 'Enter an email address associated with your account and we will send you instructions for resetting your password.'
    }
}