export const strings = {
    Username: `Username`,
    Password:  `Password`,
    VerificationCode:  `TOTP Code Use Authenticator`,
    RecoveryCode: `Backup Code`,
    UsernameValidation: `Username is required.`,
    PasswordValidation: `Password is required.`,
    CodeValidate: `Verification code is required.`,
    InCorrectNameOrPassword: `Username or password you've given is incorrect.`,
    InCorrectCodeOrExpireCode: `You've given wrong totp code or expired totp code.`,
    DenyOrExpiredApprovalRequest: `Either approval request is expired or you've denied request in authy app. Go Back and Re-Login`,
    UnexpectedErrorOccured: `Unexpected error occured, please retry or contact support.`,
    UsedOrInvalidRecoveryCode: `You've entered either used or invalid recovery code.`,
    LoggingInAs: `Logging in as: `,
    Login: `Login`,
    ForgotPassword: `Forgot password?`,
    PasswordRecovery: `Password Recovery`,
    Email: `Email`,
    Cancel: `Cancel`,
    Send: `Send`,
    NotReceivingPasswordRecoveryEmail: `Not receiving password recovery email? Email us at `,
    CheckingStatus: `checking status ...`,
    PushNotificationError: `Either request is expired or you denied the request. Try another way or Retry by clicking Back and Login`,
    TryAnotherWayToLogin: `Try another way to login.`,
    UseAuthenticator: `Use authenticator app instead`,
    UseAuthyApp: `Use authy app instead`,
    MultiFactorVerification: `Multi-factor verification`,
    LostMultiFactorAppAccess: `Lost multi-factor app access?`,
    ReactivateMultiFactor: `Reactivate multi-factor authentication.`,
    BackupCodeLabel: `Enter one of the 8 digit backup code.`,
    VerificationCodeLabel: `Enter 6 digit verification code.`,
    RememberMeNote: `Use Remember Me in order to skip multifactor for future logins.
    Which will be valid for 30 days.`,
    RememberMe: `Remember Me`,
    Back: `Back`,
    InvalidEmailErrorMessage: `Please enter the valid Email.`,
    ForgotPasswordEmailResetDescripton: `Enter an email address associated with your account and we will send you instructions for resetting your password.`,
    ForgotPasswordEmailDescription: `An email with further instructions was sent to you. If you did not received any, please contact Zacco.`,
    ResetLinkErrorMessage:`Unexpected error occured while getting password reset link, please try again`
}