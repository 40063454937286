import React from 'react';
import { ILoaderProps } from './ILoaderProps';
import styles from './Loader.module.scss';
import { LoaderType } from './LoaderType';

export const Loader: React.FunctionComponent<ILoaderProps> = (props: ILoaderProps) =>
{
    const loaderType: LoaderType = props.type === undefined ? LoaderType.Default : props.type;
    const loading: JSX.Element = <div className={styles.loading}> Loading &#8230;</div>;
    if (loaderType === LoaderType.Default)
    {
        return loading;
    }
    return <div className={styles.loaderContainer}>{loading}</div>;
}