import * as React from "react";
import * as ReactDom from "react-dom";
import { BrowserRouter } from "react-router-dom";
import StartPage from "./components/StartPage";
import { unregister } from "./registerServiceWorker";
import "whatwg-fetch";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { loadTheme, createTheme } from "@fluentui/react/lib/Styling";
import { ipViewTheme } from "./utils/IPviewTheme";
import { initializeFileTypeIcons } from "@uifabric/file-type-icons";
import 'dynamic-table/dist/index.css';

loadTheme(createTheme(ipViewTheme));

initializeFileTypeIcons();

initializeIcons();

unregister();

const App = () => (
    <BrowserRouter>
        <StartPage apiUrl={window.location.origin + "/api"}></StartPage>
    </BrowserRouter>
);

ReactDom.render(<App />, document.getElementById("root"));
