import styles from "./Layout.module.scss";
import React from "react";


export default class Layout extends React.Component {
    public render(): React.ReactNode {
        return (
            <div className={styles.layout}>
                <div className={"ms-Grid"} dir="ltr">
                    <div className={styles.row}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}