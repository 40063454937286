import { IconButton } from "@fluentui/react/lib/Button";
import React from "react";
import { Constants } from "../../Constants";
import styles from "../ForgotPassword.module.scss";
import { strings } from "../../strings";
import { IForgotMultiFactorProps } from "./IForgotMultiFactorProps";

export const ForgotMultiFactor: React.FunctionComponent<IForgotMultiFactorProps> = (props: IForgotMultiFactorProps) => {
    return <div className={styles.dialog}>
    <div className={styles.heading}>
        <div className={styles.title}>{strings.ReactivateMultiFactor}</div>
        <div className={styles.actions}></div>
        <div className={styles.closeAction}>
            <IconButton iconProps={{ iconName: "ChromeClose" }} onClick={props.onClose} />
        </div>
    </div>    
    <React.Fragment>
        <div>
            <div className={styles.description}>Didn't have access to your multi-factor authentication keys. (Authenticator or Authy app)? Email us at <a href={`mailto:${Constants.Mail.IPView}`}>{Constants.Mail.IPView}</a></div>
        </div>
    </React.Fragment>
</div>
}
