import { DefaultButton, IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { TextField } from "@fluentui/react/lib/TextField";
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import React from "react";
import styles from "./ForgotPassword.module.scss";
import { IForgotPasswordProps } from "./IForgotPasswordProps";
import { IForgotPasswordState } from "./IForgotPasswordState";
import { strings } from "../strings";
import { Constants } from "../Constants";
import EmailValidatorHelper from "../../../utils/EmailValidatorHelper";


export class ForgotPassword extends React.Component<IForgotPasswordProps, IForgotPasswordState> {
    constructor(props: any) {
        super(props);
        this.state = {
            email: "",
            linkSent: false,
            error: "",
            loading: false,
            disableSendButton: true
        };
    }

    public componentDidMount(): void {
        const { email } = this.props;
        this.validateAndSetEmail(email);
    }

    public render(): React.ReactNode {
        const { email, loading, disableSendButton } = this.state;
        const error = this.state.error != "" ? (
            <div style={{ paddingTop: "10px" }}>
                <label style={{ color: "red" }}>{this.state.error}</label>
            </div>
        ) : (
            ""
        );
        return (
            <div className={styles.dialog}>
                <div className={styles.heading}>
                    <div className={styles.title}>{strings.PasswordRecovery}</div>
                    <div className={styles.actions}></div>
                    <div className={styles.closeAction}>
                        <IconButton iconProps={{ iconName: "ChromeClose" }} onClick={this.props.onClose} />
                    </div>
                </div>
                {!this.state.linkSent ? (
                    <React.Fragment>
                        <div className={styles.description}>{strings.ForgotPasswordEmailResetDescripton}</div>
                        <div className={styles.form}>
                            <TextField
                                name="email"
                                aria-label={strings.Email}
                                placeholder={strings.Email}
                                className={styles.formControl}
                                value={email}
                                onChange={this.handleEmailChange}
                            />
                            <div className={styles.formActions}>
                                <DefaultButton onClick={this.props.onClose}>{strings.Cancel}</DefaultButton>
                                <div className={styles.primaryAction}>{loading && <Spinner styles={{
                                    root: styles.loading
                                }} size={SpinnerSize.small} />}<PrimaryButton disabled={loading ||disableSendButton} onClick={this.handleSubmit}>{strings.Send}</PrimaryButton></div>
                            </div>
                        </div>
                        {error}
                    </React.Fragment>) : (
                    <React.Fragment>
                        <div>
                            <span className={styles.description}>{strings.ForgotPasswordEmailDescription}</span>
                        </div>
                    </React.Fragment>
                )}
                <div className={styles.note}><span>{strings.NotReceivingPasswordRecoveryEmail}</span><span>{<a href={`mailto:${Constants.Mail.IPView}`}>{Constants.Mail.IPView}</a>}</span></div>
            </div>
        );
    }

    private validateAndSetEmail = (value?: string): void => {
        const isValidEmail: boolean = value != null ? EmailValidatorHelper.isValidEmail(value) : false;
        this.setState((prevState: IForgotPasswordState): IForgotPasswordState => {
            prevState.email = value || '';    
            prevState.disableSendButton = !isValidEmail;
            prevState.error = isValidEmail? '' : strings.InvalidEmailErrorMessage;        
            return prevState;
        });
    }
  
    private handleEmailChange = (event, value): void => {
        this.validateAndSetEmail(value);
    }

    private handleSubmit = (event): void => {
        this.setState((prevState: IForgotPasswordState): IForgotPasswordState => {
            prevState.loading = true;
            return prevState;
        }, async () => {
            try {
                event.persist();
                let forgotPassword = { email: this.state.email };
                let authTokenResponse = await this.props.connectionContext.post("/managepassword/forgot", forgotPassword);

                if (authTokenResponse == null) {
                    this.setState((prevState: IForgotPasswordState): IForgotPasswordState => {
                        prevState.linkSent = true;
                        prevState.loading = false;
                        prevState.error = '';
                        return prevState;
                    });
                }
            } catch (e) {
                const error: { code: number | undefined, body: Promise<any> | undefined } | null = e as { code: number | undefined, body: Promise<any> | undefined } | null;
                if (error != null && error.code != undefined) {
                    const errorContent: { success: boolean, message: string } = await e.body;
                    this.setState((prevState: IForgotPasswordState): IForgotPasswordState => {
                        prevState.linkSent = false;
                        prevState.loading = false;
                        prevState.error = errorContent.message;
                        return prevState;
                    });
                }
            }
        });
    }
}
