import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { TextField } from '@fluentui/react/lib/TextField';
import React from "react";
import { Link } from "react-router-dom";
import { PasswordStrengthTypeEnum } from '../../models/PasswordStrengthTypeEnum';
import { PasswordConfirmationDialog } from '../Common/PasswordStrength/PasswordConfirmationDialog/PasswordConfirmationDialog';
import { PasswordStrength } from '../Common/PasswordStrength/PasswordStrength';
import PasswordStrengthHelper from '../Common/PasswordStrength/PasswordStrengthHelper';
import styles from "./ResetPassword.module.scss";
import { IPasswordStrengthDetails } from '../Common/PasswordStrength/Interface/IPasswordStrengthDetails';
import { ProfileDataManager } from '../Profile/ProfileDataManager';
import ConnectionContext from '../../utils/ConnectionContext';

export interface IResetPasswordState {
    password: string;
    confirmPassword: string;
    validResetLink: boolean;
    verifyingResetLink: boolean;
    passwordResetSuccess: boolean;
    error: string;
    passwordStrengthDetails: IPasswordStrengthDetails;
    shouldShowPasswordConfirmationDialog: boolean;
    isLoading: boolean;
}

export default class ResetPassword extends React.Component<any, IResetPasswordState> {
    private dataManager: ProfileDataManager;
    constructor(props: any) {
        super(props);
        this.state = {
            password: "",
            confirmPassword: "",
            validResetLink: false,
            verifyingResetLink: false,
            passwordResetSuccess: false,
            error: "",
            passwordStrengthDetails:
            {
                estimatedTimeToCrack: '',
                strengthType: PasswordStrengthTypeEnum.NotSpecified,
                strengthText: ''
            },
            shouldShowPasswordConfirmationDialog: false,
            isLoading: false
        };

        this.dataManager = new ProfileDataManager();
    }

    async componentDidMount() {
        await this.validateResetLink();
    }

    validateResetLink = async (): Promise<boolean> => {
        let isValidResetLink = false;
        try {
            const token = ConnectionContext.getQueryStringByParameter('token');
            const authTokenResponse = await this.dataManager.validatePasswordResetToken(token);
            if (authTokenResponse !== undefined && authTokenResponse == null) {
                this.setState((prevState: IResetPasswordState): IResetPasswordState => {
                    prevState.validResetLink = true;
                    prevState.verifyingResetLink = true;
                    return prevState;
                });
                isValidResetLink = true;
            }
        } catch (e) {
            this.setState((prevState: IResetPasswordState): IResetPasswordState => {
                prevState.validResetLink = false;
                prevState.verifyingResetLink = true;
                return prevState;
            });
            isValidResetLink = false;
        }

        return isValidResetLink;
    }

    handlePasswordChange = (event) => {
        let value = event.target.value;
        this.setState((prevState: IResetPasswordState): IResetPasswordState => {
            prevState.password = value;
            return prevState;
        });
        this.getPasswordStrengthDetails(value);
    }

    handleConfirmPasswordChange = (event) => {
        let value = event.target.value;
        this.setState((prevState: IResetPasswordState): IResetPasswordState => {
            prevState.confirmPassword = value;
            return prevState;
        });
    }

    handleSubmit = async () => {
        let isValidResetLink = await this.validateResetLink();

        if (!isValidResetLink) {
            return;
        }
        const passwordStrengthType: PasswordStrengthTypeEnum = this.getPasswordStrengthType();
        if (passwordStrengthType === PasswordStrengthTypeEnum.VeryWeak ||
            passwordStrengthType === PasswordStrengthTypeEnum.Weak) {
            this.setState((prevState: IResetPasswordState): IResetPasswordState => {
                prevState.shouldShowPasswordConfirmationDialog = true;
                return prevState;
            });
        }
        else {
            this.submitPasswordChangeRequest();
        }
    }

    private submitPasswordChangeRequest = async () => {
        this.setState((prevState: IResetPasswordState): IResetPasswordState => {
            prevState.isLoading = true;
            prevState.shouldShowPasswordConfirmationDialog = false;
            return prevState;
        });
        await this.getPasswordChangeApiResponse();
        this.setState((prevState: IResetPasswordState): IResetPasswordState => {
            prevState.isLoading = false;
            return prevState;
        });
    }

    private getPasswordChangeApiResponse = async () => {
        try {
            const { password, confirmPassword } = this.state;
            let resetPasswordData = { password: password, confirmPassword: confirmPassword };
            const token = ConnectionContext.getQueryStringByParameter('token');
            let authTokenResponse = await this.dataManager.resetPasswordUsingLink(resetPasswordData, token);

            if (authTokenResponse == null) {
                this.setState((prevState: IResetPasswordState): IResetPasswordState => {
                    prevState.passwordResetSuccess = true;
                    prevState.error = '';
                    return prevState;
                });
            }
        }
        catch (e) {
            this.setState((prevState: IResetPasswordState): IResetPasswordState => {
                prevState.passwordResetSuccess = false;
                prevState.error = 'Unexpected error occured while resetting password';
                return prevState;
            });
        }
    }

    renderIntroduction = () => {
        return <div className={styles.introduction}>
            <div className={styles.header}>
                <div className={styles.content}>
                    <div>
                        TO <strong>MANAGE</strong>, <strong>CONTROL</strong>
                    </div>
                    <div>
                        AND GET AN <strong>OVERVIEW</strong>
                    </div>
                    <div>
                        OF YOUR IP PORTFOLIO
                    </div>
                </div>
            </div>
            <div className={styles.body}>
                <div className={styles.content}>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.content}>
                </div>
            </div>
        </div>;
    }

    render() {
        const error = this.state.error != "" ? (
            <div style={{ paddingTop: "10px" }}>
                <label style={{ color: "red" }}>{this.state.error}</label>
            </div>
        ) : (
            ""
        );
        return (
            <div className={styles.container} >
                <div className={styles.page}>
                    <div className={styles.header}>
                        <div className={styles.content}>
                        </div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.content}>
                            <div className={styles.form}>
                                <div className={styles.formName}>
                                    Reset Your Password
                                </div>
                                {!this.state.passwordResetSuccess ?
                                    !this.state.verifyingResetLink ? (<Spinner size={SpinnerSize.large} label="Verifying password reset request..." labelPosition="top" />)
                                        : this.state.validResetLink ? (<div>
                                            <div className={styles.formControl}>
                                                You can reset your password by providing details below.
                                            </div>
                                            <TextField
                                                aria-label="New Password"
                                                placeholder="New Password"
                                                className={styles.formControl + ' ' + styles.passwordStrengthControl}
                                                type="password"
                                                defaultValue={this.state.password}
                                                onChange={this.handlePasswordChange}
                                                validateOnLoad={false}
                                                validateOnFocusOut
                                            />
                                            <PasswordStrength
                                                passwordStrengthType={this.getPasswordStrengthType()}
                                                passowrdStrengthText={this.state.passwordStrengthDetails.strengthText}
                                            />
                                            <TextField
                                                aria-label="Confirm Password"
                                                placeholder="Confirm Password"
                                                className={styles.formControl}
                                                type="password"
                                                defaultValue={this.state.confirmPassword}
                                                onChange={this.handleConfirmPasswordChange}
                                                onGetErrorMessage={this.getErrorMessageForConfirmPassword}
                                                validateOnLoad={false}
                                                validateOnFocusOut
                                            />
                                            <div className={styles.buttonContainer}>
                                                <PrimaryButton
                                                    onClick={this.handleSubmit}
                                                    disabled={this.shouldDisableSubmitButton()}>Submit</PrimaryButton>
                                                <Link to="login">
                                                    <DefaultButton>Cancel</DefaultButton>
                                                </Link>
                                            </div>
                                            {error}
                                        </div>
                                        ) : (<div className={styles.formControl}>Password reset link expired, Please <Link to="forgotpassword">click here to request for password reset again</Link></div>)
                                    : (<div className={styles.formControl}>Your password has been reset. Please <Link to="login">click here to log in</Link></div>)}
                            </div>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.content}>
                            Not Registered? <a href="accountRequest">Ask for an account</a>
                        </div>
                    </div>
                </div>
                {this.renderIntroduction()
                }
                {this.state.shouldShowPasswordConfirmationDialog &&
                    < PasswordConfirmationDialog
                        shouldShowPasswordConfirmationDialog={this.state.shouldShowPasswordConfirmationDialog}
                        isLoading={this.state.isLoading}
                        passwordStrengthDetails={this.state.passwordStrengthDetails}
                        onSubmitPasswordChangeRequest={this.submitPasswordChangeRequest}
                        onCancelWeakPasswordConfirmation={this.cancelWeakPasswordConfirmation}
                    />
                }
            </div >
        );
    }

    private getPasswordStrengthType = (): PasswordStrengthTypeEnum => {
        return this.state.passwordStrengthDetails !== null ?
            this.state.passwordStrengthDetails.strengthType : PasswordStrengthTypeEnum.NotSpecified;
    }

    private getErrorMessageForConfirmPassword = (value: string) => {
        return PasswordStrengthHelper.getErrorMessageForConfirmPassword(this.state.password, value);
    }

    private getPasswordStrengthDetails = (passwordValue: string) => {
        const passwordStrengthDetails: IPasswordStrengthDetails = PasswordStrengthHelper.getPasswordStrengthDetails(passwordValue);
        this.setState((prevState: IResetPasswordState): IResetPasswordState => {
            prevState.passwordStrengthDetails = passwordStrengthDetails;
            return prevState;
        });
    }

    private shouldDisableSubmitButton = (): boolean => {
        return PasswordStrengthHelper.shouldDisableSubmitButton(this.state.password, this.state.confirmPassword)
            || this.state.isLoading;
    }

    private cancelWeakPasswordConfirmation = () => {
        this.setState((prevState: IResetPasswordState): IResetPasswordState => {
            prevState.shouldShowPasswordConfirmationDialog = false;
            return prevState;
        });
    }
}