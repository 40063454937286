import React, { Suspense } from "react";
import { Redirect } from "react-router";
import CurrentUser from "../../models/CurrentUser";
import { CurrentUserHelper } from "../../services/CurrentUserHelper";
import { Loader } from "../Common/Loader/Loader";
import { LoaderType } from "../Common/Loader/LoaderType";
import { Constants } from "../Login/Constants";
import { IAppProps } from "./IAppProps";
import { IAppState } from "./IAppState";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
const MainComponent = React.lazy(() => import(/* webpackChunkName: "Secured_MainComponent" */'../MainComponent/MainComponent'));

class App extends React.Component<IAppProps, IAppState> {
    constructor(props: IAppProps) {
        super(props);
        this.state = {
            currentUser: null,
            loading: true
        }
    }

    public async componentDidMount(): Promise<void> {
        this.setLoading(true);
        await this.loadCurrentUser();
        this.setLoading(false);
        document.addEventListener(Constants.Events.UnAuthorizedEvent, this.revokeCurrentUserData);
    }

    public componentWillUnmount(): void {
        document.removeEventListener(Constants.Events.UnAuthorizedEvent, this.revokeCurrentUserData);
    }

    public render(): React.ReactNode {
        const { currentUser, loading }: IAppState = this.state;
        const fallbackLoader = <Loader type={LoaderType.Fallback} />;
        if (loading) {
            return fallbackLoader;
        }

        if (currentUser == undefined || currentUser === null) {

            return <Redirect
                to={{
                    pathname: "/login",
                    state: {
                        referrer: `${location.pathname}${location.search}`,
                    },
                }}
            />
        }

        return <CurrentUserContext.Provider value={currentUser}>
            <Suspense fallback={fallbackLoader}>
                <MainComponent currentUser={currentUser} />
            </Suspense>
        </CurrentUserContext.Provider>;
    }

    private revokeCurrentUserData = () => {
        this.setState((prevState: IAppState): IAppState => {
            prevState.currentUser = null;
            return prevState;
        });
    }

    private loadCurrentUser = async (): Promise<void> => {
        let currentUserInfo: CurrentUser | null = await CurrentUserHelper.getCurrentUserValue();
        this.setState((prevState: IAppState): IAppState => {
            prevState.currentUser = currentUserInfo;
            return prevState;
        });
    }

    private setLoading = (loading: boolean) => {
        this.setState((prevState: IAppState): IAppState => {
            prevState.loading = loading;
            return prevState;
        });
    }
}

export default App;