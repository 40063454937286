export const Constants = {
    Headers: {
        Device: 'X-IPView-Device'
    },
    Tokens: {
        Device: 'device_token',
        AccessToken: 'access_token',
        RefreshToken: 'refresh_token',
        RedirectUrl: 'redirect_url'
    },
    Mail: {
        IPView: "ipview@zacco.com"
    },
    Events: {
        UnAuthorizedEvent: 'unauthorized'
    },
    User: {
        PasswordStrength: 'password_strength'
    }
}