import React from "react";
import { IImpersonateProps } from "./IImpersonateProps";
import { IImpersonateState } from "./IImpersonateState";
import { Redirect } from "react-router-dom";
import { Loader } from "../../Common/Loader/Loader";
import { LoaderType } from "../../Common/Loader/LoaderType";
import ConnectionContext from "../../../utils/ConnectionContext";

export default class Impersonate extends React.Component<IImpersonateProps, IImpersonateState> {
    constructor(props: IImpersonateProps, state: IImpersonateState) {
        super(props);
        this.state = {
            isAuthenticated: false
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.endActiveUserSession();
        const ticket = ConnectionContext.getQueryStringByParameter('ticket');
        if (ticket === undefined) {
            this.redirectToLogin();
            return;
        }
        await this.handleTicketLogin(ticket);
    }

    public render(): React.ReactNode {
        if (this.state.isAuthenticated) {
            return <Redirect to="/dashboard" />;
        }
        return <Loader type={LoaderType.Fallback} />;
    }

    private handleTicketLogin = async (ticket): Promise<void> => {
        try {
            await this.props.connectionContext.post(`/authentication/ticket/${ticket}`, {});
            this.setState((prevState: IImpersonateState): IImpersonateState => {
                prevState.isAuthenticated = true;
                return prevState;
            });
        }
        catch(error) {
            this.redirectToLogin();            
        }
    }

    private redirectToLogin = (): void => {
        const { routeProps: { history } } = this.props;
        history && history.push && history.push({pathname: '/login'});
    }

    private endActiveUserSession = async (): Promise<void> => {
        try {
            await this.props.connectionContext.delete("/logout");
        }
        finally {
            return;
        }
    } 
}
