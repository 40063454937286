import styles from '../components/Layout/ThemeVariables.module.scss';

export const ipViewTheme = {
    defaultFontStyle: { fontFamily: styles.fontName, color: styles.neutralPrimary },
    palette: {
        themePrimary: styles.themePrimary,
        themeLighterAlt: styles.themeLighterAlt,
        themeLighter: styles.themeLighter,
        themeLight: styles.themeLight,
        themeTertiary: styles.themeTertiary,
        themeSecondary: styles.themeSecondary,
        themeDarkAlt: styles.themeDarkAlt,
        themeDark: styles.themeDark,
        themeDarker: styles.themeDarker,
        neutralLighterAlt: styles.neutralLighterAlt,
        neutralLighter: styles.neutralLighter,
        neutralLight: styles.neutralLight,
        neutralQuaternaryAlt: styles.neutralQuaternaryAlt,
        neutralQuaternary: styles.neutralQuaternary,
        neutralTertiaryAlt: styles.neutralTertiaryAlt,
        neutralTertiary: styles.neutralTertiary,
        neutralSecondary: styles.neutralSecondary,
        neutralPrimaryAlt: styles.neutralPrimaryAlt,
        neutralPrimary: styles.neutralPrimary,
        neutralDark: styles.neutralDark,
        black: styles.black,
        white: styles.white,
    }
}