import { ProgressIndicator } from "@fluentui/react/lib/ProgressIndicator";
import styles from "./PasswordStrength.module.scss";
import * as React from "react";
import PasswordStrengthHelper from "./PasswordStrengthHelper";
import { PasswordStrengthTypeEnum } from "../../../models/PasswordStrengthTypeEnum";
import { IPasswordStrengthBarStylingDetails } from "./Interface/IPasswordStrengthStylingDetails";

export interface IPasswordStrengthProps {
    strengthLabelClassName?: string;
    passwordStrengthType: PasswordStrengthTypeEnum;
    passowrdStrengthText: string;
}

export const PasswordStrength: React.FunctionComponent<IPasswordStrengthProps> = (props: IPasswordStrengthProps): JSX.Element => {
    const { passwordStrengthType, passowrdStrengthText, strengthLabelClassName } = props;
    let passwordStrengthBarStylingDetails: IPasswordStrengthBarStylingDetails = getProgressBarStrengthSpecificStyling(passwordStrengthType);
    const passwordStrengthBarValue = PasswordStrengthHelper.getPasswordStrengthScore(passwordStrengthType);

    if (strengthLabelClassName) {
        passwordStrengthBarStylingDetails.strengthLabelClassName = strengthLabelClassName;
    }

    return (<div className={passwordStrengthBarStylingDetails.progressBarClassName}>
        <ProgressIndicator
            percentComplete={passwordStrengthBarValue}
        />
        <div className={styles.passwordStrengthLabelContainer}>
            <label className={passwordStrengthBarStylingDetails.strengthLabelClassName}>
                {passowrdStrengthText}
            </label>
        </div>
    </div>);
}

const getProgressBarStrengthSpecificStyling = (passwordStrengthType: PasswordStrengthTypeEnum): IPasswordStrengthBarStylingDetails => {
    switch (passwordStrengthType) {
        case PasswordStrengthTypeEnum.VeryWeak:
            return {
                progressBarClassName: styles.passwordStrengthBarVeryWeak,
                strengthLabelClassName: styles.passwordStrengthLabelVeryWeak
            } as IPasswordStrengthBarStylingDetails;
        case PasswordStrengthTypeEnum.Weak:
            return {
                progressBarClassName: styles.passwordStrengthBarWeak,
                strengthLabelClassName: styles.passwordStrengthLabelWeak
            } as IPasswordStrengthBarStylingDetails;
        case PasswordStrengthTypeEnum.Good:
            return {
                progressBarClassName: styles.passwordStrengthBarGood,
                strengthLabelClassName: styles.passwordStrengthLabelGood
            } as IPasswordStrengthBarStylingDetails;
        case PasswordStrengthTypeEnum.Strong:
            return {
                progressBarClassName: styles.passwordStrengthBarStrong,
                strengthLabelClassName: styles.passwordStrengthLabelStrong
            } as IPasswordStrengthBarStylingDetails;
        default: return {
            progressBarClassName: '',
            strengthLabelClassName: ''
        } as IPasswordStrengthBarStylingDetails;
    }
}