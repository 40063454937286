import React from "react";
import { Redirect } from "react-router-dom";
import { Constants } from "../Login/Constants";

export default class Logout extends React.Component<any, any> {
    constructor(props: any, state: any) {
        super(props);
        this.state={
            logoutSuccess: false
        }
    }

    componentDidMount() {
        localStorage.removeItem(Constants.Tokens.RedirectUrl);

        this.setState({logoutSuccess:true});
    }
    render() {
        if(this.state.logoutSuccess){
            return <Redirect to="/login" />;
         }
        return null;
    }
}