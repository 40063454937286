import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { TextField } from "@fluentui/react/lib/TextField";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./ForgotPassword.module.scss";
import EmailValidatorHelper from "../../utils/EmailValidatorHelper";
import ForgotPasswordResource from "./ForgotPassword.Resource";

export interface IForgotPasswordState {
    email: string;
    linkSent: boolean;
    error: string;
    disableSendButton: boolean;
}

export default class ForgotPassword extends React.Component<any, IForgotPasswordState> {
    constructor(props: any) {
        super(props);
        this.state = {
            email: "",
            linkSent: false,
            error: "",
            disableSendButton: true
        };
    }

    public render(): React.ReactNode {
        const { email, disableSendButton} = this.state;
        const error = this.state.error != "" ? (
            <div style={{ paddingTop: "10px" }}>
                <label style={{ color: "red" }}>{this.state.error}</label>
            </div>
        ) : (
            ""
        );

        return (
            <div className={styles.container}>
                <div className={styles.page}>
                    <div className={styles.header}>
                        <div className={styles.content}>
                        </div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.content}>
                            <div className={styles.form}>
                                <div className={styles.formName}>{ForgotPasswordResource.informations.recoverYourPassword}</div>
                                {!this.state.linkSent ? (
                                    <React.Fragment>
                                        <div className={styles.formControl}>
                                            {ForgotPasswordResource.informations.forgotPasswordEmailResetDescripton}
                                        </div>
                                        <TextField
                                            aria-label={ForgotPasswordResource.labels.email}
                                            placeholder={ForgotPasswordResource.labels.email}
                                            name="email"
                                            value={email}
                                            onChange={this.handleEmailChange}
                                            className={styles.formControl}
                                        />
                                        <div className={styles.formActions}>
                                            <Link to="login">
                                                <DefaultButton>{ForgotPasswordResource.labels.buttons.cancel}</DefaultButton>
                                            </Link>
                                            <PrimaryButton type="submit" onClick={this.handleSubmit} disabled={disableSendButton}>{ForgotPasswordResource.labels.buttons.submit}</PrimaryButton>
                                        </div>
                                        {error}
                                    </React.Fragment>) : (
                                    <React.Fragment>
                                        <div className={styles.formControl}>An email with further instructions was sent to you. If you did not received any e-mail,  email us at <a href={`mailto:ipview@zacco.com`}>ipview@zacco.com</a>.</div>
                                        <div className={styles.formControl}><Link to="login">{ForgotPasswordResource.labels.backToLogin}</Link></div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.content}>
                            Not Registered? <a href="">Ask for an account</a>
                        </div>
                    </div>
                </div>
                {this.renderIntroduction()}
            </div>
        );
    }

    private renderIntroduction = (): React.ReactNode => {
        return <div className={styles.introduction}>
            <div className={styles.header}>
                <div className={styles.content}>
                    <div>
                        TO <strong>MANAGE</strong>, <strong>CONTROL</strong>
                    </div>
                    <div>
                        AND GET AN <strong>OVERVIEW</strong>
                    </div>
                    <div>
                        OF YOUR IP PORTFOLIO
                    </div>
                </div>
            </div>
            <div className={styles.body}>
                <div className={styles.content}>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.content}>
                </div>
            </div>
        </div>;
    }

    private validateAndSetEmail = (value: string): void => {
        const isValidEmail: boolean = EmailValidatorHelper.isValidEmail(value);
        this.setState((prevState: IForgotPasswordState): IForgotPasswordState => {
            prevState.email = value;
            prevState.disableSendButton = !isValidEmail;
            prevState.error = isValidEmail ? '' : ForgotPasswordResource.errorMessages.invalidEmailErrorMessage;
            return prevState;
        });
    }

    private handleEmailChange = (event, value): void => {
        this.validateAndSetEmail(value);
    }

    private handleSubmit = async (event): Promise<void> => {
        try {
            let forgotPassword = { email: this.state.email };
            await this.props.connectionContext.post("/managepassword/forgot", forgotPassword);

            this.setState((prevState: IForgotPasswordState): IForgotPasswordState => {
                prevState.linkSent = true;
                prevState.error = '';
                return prevState;
            });
        } catch (error) {
            const e: { code: number | undefined, body: Promise<any> | undefined } | null = error as { code: number | undefined, body: Promise<any> | undefined } | null;

            if (e != null && e.code != undefined) {
                const errorContent: { success: boolean, message: string } = await e.body;
                this.setState((prevState: IForgotPasswordState): IForgotPasswordState => {
                    prevState.linkSent = false;
                    prevState.error = errorContent.message;
                    return prevState;
                });
            }
        }
    }
}
