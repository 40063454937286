import PasswordStrengthResource from "./PasswordStrength.resource";
import zxcvbn from "zxcvbn";
import { PasswordStrengthTypeEnum } from "../../../models/PasswordStrengthTypeEnum";
import { IPasswordStrengthDetails } from "./Interface/IPasswordStrengthDetails";

export default class PasswordStrengthHelper {
    private static maximumPasswordStrengthScore: number = 4;
    public static getErrorMessageForConfirmPassword = (password: string, confirmPassword: string) => {
        return confirmPassword === null || confirmPassword === '' ? PasswordStrengthResource.errorMessages.emptyConfirmPassword :
            (confirmPassword !== password ? PasswordStrengthResource.errorMessages.invalidConfirmPassword : '');
    }

    public static getPasswordStrengthDetails = (passwordValue: string): IPasswordStrengthDetails => {
        if (passwordValue !== null && passwordValue !== '') {
            const passwordStrengthResponse = zxcvbn(passwordValue);
            const passwordStrengthScore = passwordStrengthResponse !== null ? passwordStrengthResponse.score : 0;
            let passwordStrengthType: PasswordStrengthTypeEnum = PasswordStrengthTypeEnum.NotSpecified;
            let passwordStrengthText: string = '';
            switch (passwordStrengthScore) {
                case 0:
                case 1:
                    passwordStrengthType = PasswordStrengthTypeEnum.VeryWeak;
                    passwordStrengthText = PasswordStrengthResource.labels.progressBarStatusTexts.veryWeakPasswordText;
                    break;
                case 2:
                    passwordStrengthType = PasswordStrengthTypeEnum.Weak;
                    passwordStrengthText = PasswordStrengthResource.labels.progressBarStatusTexts.weakPasswordText;
                    break;
                case 3:
                    passwordStrengthType = PasswordStrengthTypeEnum.Good;
                    passwordStrengthText = PasswordStrengthResource.labels.progressBarStatusTexts.goodPasswordText;
                    break;
                case 4:
                    passwordStrengthType = PasswordStrengthTypeEnum.Strong;
                    passwordStrengthText = PasswordStrengthResource.labels.progressBarStatusTexts.strongPasswordText;
                    break;
            }
            return {
                strengthText: passwordStrengthText,
                strengthType: passwordStrengthType,
                estimatedTimeToCrack: passwordStrengthResponse !== null ? passwordStrengthResponse.crack_times_display.offline_slow_hashing_1e4_per_second : ''
            } as IPasswordStrengthDetails;
        }
        else {
            return {} as IPasswordStrengthDetails;
        }
    }

    public static getPasswordStrength = (passwordValue: string): PasswordStrengthTypeEnum => {
        let passwordStrengthType: PasswordStrengthTypeEnum = PasswordStrengthTypeEnum.NotSpecified;
        if (passwordValue !== null && passwordValue !== '') {
            const passwordStrengthResponse = zxcvbn(passwordValue);
            const passwordStrengthScore = passwordStrengthResponse !== null ? passwordStrengthResponse.score : 0;

            switch (passwordStrengthScore) {
                case 0:
                case 1:
                    passwordStrengthType = PasswordStrengthTypeEnum.VeryWeak;
                    break;
                case 2:
                    passwordStrengthType = PasswordStrengthTypeEnum.Weak;
                    break;
                case 3:
                    passwordStrengthType = PasswordStrengthTypeEnum.Good;
                    break;
                case 4:
                    passwordStrengthType = PasswordStrengthTypeEnum.Strong;
                    break;
            }
        }
        return passwordStrengthType;
    }

    public static getPasswordStrengthText = (passwordStrengthType: PasswordStrengthTypeEnum): string => {
        let passwordStrengthText: string = '';
        switch (passwordStrengthType) {
            case PasswordStrengthTypeEnum.VeryWeak:
                passwordStrengthText = PasswordStrengthResource.labels.progressBarStatusTexts.veryWeakPasswordText;
                break;
            case PasswordStrengthTypeEnum.Weak:
                passwordStrengthText = PasswordStrengthResource.labels.progressBarStatusTexts.weakPasswordText;
                break;
            case PasswordStrengthTypeEnum.Good:
                passwordStrengthText = PasswordStrengthResource.labels.progressBarStatusTexts.goodPasswordText;
                break;
            case PasswordStrengthTypeEnum.Strong:
                passwordStrengthText = PasswordStrengthResource.labels.progressBarStatusTexts.strongPasswordText;
                break;
            default:
                passwordStrengthText = "";
                break;
        }
        return passwordStrengthText;
    }

    public static getPasswordStrengthScore = (passwordStrengthType: PasswordStrengthTypeEnum): number => {
        const passwordStrengthScore: number = passwordStrengthType !== undefined
            && passwordStrengthType !== null ? passwordStrengthType.valueOf() : 0
        return passwordStrengthScore / PasswordStrengthHelper.maximumPasswordStrengthScore;
    }

    public static shouldDisableSubmitButton = (password: string, confirmPassword: string): boolean => {
        return (password === null || password === '') || confirmPassword != password;
    }
}