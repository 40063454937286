import * as queryString from 'query-string';
import { Constants } from '../components/Login/Constants';
import { IOptions } from "../models/IOptions";
import { ResponseType } from "../models/ResponseType";
import { generateQueryString } from './QueryParamsHelper';

export default class ConnectionContext {
    private baseUrl: string;

    constructor(apiUrl: string) {
        this.baseUrl = apiUrl;
    }

    public async get(action: string, options?: IOptions): Promise<any> {
        try {
            let responseType: ResponseType = ResponseType.json;
            let headers: any = {};
            let queryString: string = '';

            if (options != undefined) {
                if (options.responseType != undefined) {
                    responseType = options.responseType;
                }
                if (options.headers != undefined) {
                    headers = options.headers;
                }
                if (options.queryParams != undefined) {
                    queryString = generateQueryString(options.queryParams);
                }
            }


            let response: Response = await fetch(this.baseUrl + action + queryString, { headers });
            if (response.status == 401) {
                this.RedirectToLoginPage();
                return;
            }
            if (response.status === 204 || response.status === 404) {
                return null;
            }
            if (response.ok) {
                switch (responseType) {
                    case ResponseType.text:
                        return response.text();  //TODO need to remove as part of refactorization to use json instead
                    case ResponseType.json:
                    default:
                        return response.json();
                }
            } else {
                throw null;
            }
        } catch (exception) {
            throw exception;
        }
    }

    public async head(action: string, headerNames: Array<string>, options?: IOptions): Promise<any> {
        try {
            let responseType: ResponseType = ResponseType.json;
            let headers: any = {};

            if (options != undefined) {
                if (options.responseType != undefined) {
                    responseType = options.responseType;
                }
                if (options.headers != undefined) {
                    headers = options.headers;
                }
            }

            let response: Response = await fetch(this.baseUrl + action, {
                method: 'head',
                headers
            });
            if (response.status == 401) {
                this.RedirectToLoginPage();
                return;
            }
            if (response.status === 204 || response.status === 404) {
                return null;
            }
            if (response.ok) {
                const headersValues = {};

                headerNames.forEach(headerName => {
                    headersValues[headerName] = response.headers.get(headerName);
                });

                return headersValues;
            } else {
                throw null;
            }
        } catch (exception) {
            throw exception;
        }
    }

    public async post(action: string, body?: any | FormData, options?: IOptions): Promise<any> {
        try {
            let headers: any = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            let responseType: ResponseType = ResponseType.json;

            if (options != undefined) {
                if (options.responseType != undefined) {
                    responseType = options.responseType;
                }

                if (options.headers != undefined) {
                    headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        ...options.headers
                    }
                }
            }
            let requestBody: any = null;

            if (body != undefined && body instanceof FormData) {
                requestBody = body;
                headers = {}
            }
            else if (body != undefined) {
                requestBody = JSON.stringify(body);
            }

            let response: Response = await fetch(this.baseUrl + action, {
                method: 'post',
                headers: headers,
                body: requestBody
            });
            if (response.status == 401) {
                this.RedirectToLoginPage();
                return;
            }
            if (response.status >= 400) {
                throw { code: response.status, body: response.json() };
            }
            if (response.status == 204) {
                return new Promise<any>((resolveCode: (response: any) => void, rejectCode: (error: string) => void): void => {
                    resolveCode(null);
                });
            } else if (response.ok) {
                switch (responseType) {
                    case ResponseType.blob:
                        return response.blob();
                    case ResponseType.text:
                        return response.text();  //TODO need to remove as part of refactorization to use json instead
                    case ResponseType.json:
                    default:
                        return response.json();
                }
            }
            else {
                throw null;
            }
        } catch (exception) {
            throw exception;
        }
    }

    public async put(action: string, body: {}): Promise<any> {
        try {
            let response: Response = await fetch(this.baseUrl + action, {
                method: 'put',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (response.status == 401) {
                this.RedirectToLoginPage();
                return;
            }
            if (response.status == 204) {
                return null;
            }
            if (response.status >= 400) {
                throw { code: response.status, body: response.json() };
            }
            if (response.ok) {
                return response.json();
            } else {
                throw null;
            }
        } catch (exception) {
            throw exception;
        }
    }

    public async delete(action: string, body?: any): Promise<any> {
        try {
            let response: Response = await fetch(this.baseUrl + action, {
                method: 'delete',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)   // TODO Delete should not contain body. Need refactorization.
            });
            if (response.status == 401) {
                this.RedirectToLoginPage();
                return;
            }
            if (response.status == 204) {
                return null;
            }
            if (response.ok) {
                return response.json();
            } else {
                throw null;
            }
        } catch (exception) {
            throw exception;
        }
    }

    public static getQueryStringByParameter(param: string): string | undefined | string[] {
        let parsed: queryString.OutputParams = queryString.parse(location.search);
        return parsed[param];
    }

    private RedirectToLoginPage() {
        const unauthorizedEvent: CustomEvent = new CustomEvent(Constants.Events.UnAuthorizedEvent);
        document.dispatchEvent(unauthorizedEvent);
    }
}