export const strings = {
    FailedToLoadProfile: `Failed to load profile for user. Please refresh the page, if problem continues contact support`,
    ChangePasswordInformation: `Now you can change your password by providing following details.`,
    PasswordValidationError: `You have validation errors in your form, Please fix inorder to change password.`,
    PasswordValidationApiError: `Either your old password didn't match or something wrong with new password requirement. Please try again. If it continues please contact support.`,
    OldPasswordRequired: `Old password is required.`,
    NewPasswordRequired: `New password is required.`,
    ConfirmNewPasswordRequired: `Confirm new password is required`,
    PasswordRequirenmentConstraintFailed: `Must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.`,
    PasswordMatchFailed: `Didn't match with new password.`,
    PasswordChangeSuccessful: `Password changed successfully for your profile.`,
    Enable2FactorInformation: `Enable timebased otp authentication to make your authentication more secure by adding one more factor.
    Here you can enable 2 factor authentication by clicking on enable and following the instruction.
    You can disable this feature any time if you are not enforced by your administrator.
    In case of any issues please contact support.`,
    EnablePushAuthInformation: ` Enable push authentication as an option to your account. [Prerequisite] Before enabling this feature you must complete your profile.
    You email and phone number is must along with your country code.
    You also need to install authy app into you phone(Android or Iphone) or desktop(Mac or Windows) and create account with same email and phone no.
    Follow the instruction to complete Push authentication for your account. In case of any issues please contact support.
    Click on enable if you want to continue to enable push authentication.`,
    GoToMyProfile: `Go to my profile`,
    TotpAuthHeading: "Timebased OTP  Authentication",
    PushAuthHeading: `Push Authentication`,
    EnableTotpAuthHeading: `Enable Timebased OTP  Authentication`,
    EnablePushAuthHeading: `Enable Push Authentication`,
    EnableTotpAuthSubHeading: `We care about security.`,
    EnablePushAuthSubHeading: `We care about security.`,
    TotpInfo: `To help you increase the security of your account, we've added the support of Timebased OTP  Authentication.
    This feature protects your account by requiring a time based code any time you log in. You'll need a one-time password app in order to enable Timebased OTP  Authentication.`,
    TotpAuthAppsInfoDescription: `The following apps support the time based one-time password(TOTP).`,
    GoogleAuthenticator: "Google Authenticator",
    Authenticator: "Authenticator",
    DuoMobile: "Duo Mobile",
    GoogleAuthenticatorDevices: `(Android/ iPhone / BlackBerry)`,
    AuthenticatorDevices: `(Android/ Windows Phone 7)`,
    DuoMobileDevices: `(Android / iPhone)`,
    Important: `Important`,
    TotpAuthenticationNote: `Upon successful activation, backup codes are provided and will be the ONLY method of access to an accout in the event the access code can not be entered.
    It is EXTREMELY important that the backup codes are saved as a fail safe for the account access.
    In the event that an account owner does not have the backup code and is locked of the account, a detailed and time consuming security process to verify account ownership must be completed for a fee.
    Please contact support for any additional questions`,
    Activate: `Activate`,
    Back: `Back`,
    ConfigureTotpAuthContent: `To configure your authenticator app, enter the secret key below, or scan the barcode instead.`,
    Or: "or",
    CompleteTotpAuthDescription: `Once your app generates the password, enter it below to verify and complete your timebased otp  authentication.
    Timebased OTP  authentication will not be active until this is verified.`,
    VerifyAndSave: `Verify & Save`,
    Disable: `Disable`,
    TotpAuthenticationDisableInformation: `Are you sure you really want to disable timebased otp authentication for your profile?`,
    PushAuthDisableInformation: `Are you sure you really want to disable push authentication for your profile?`,
    TotpAuthenticationEnabledNotification: `Timebased OTP  authentication is now enabled for the profile`,
    TotpAuthenticationDisabledNotification: `Timebased OTP  is disabled for the profile`,
    VerificationCodeValidationMessage: `Verification should not be empty`,
    BackupCodesInformation: `Please keep these backup codes somewhere safe, as this will the only way to login in case you loss your authenticator. Each code can be used once.`,
    DisableTotpAuth: `Disable Timebased OTP Authentication`,
    DisablePushAuth: `Disable push authentication`,
    UnexpectedErrorOccured: `Unexpected error occured, Please try again. If it continues the same please contact support`,
    PushAuthEnableRequestSentToApprove: `Approval request is sent to your authy app, Approve that request in order to enable push authentication.`,
    PushAuthEnabledForAccount: `Push authentication of your account is enabled`,
    EnterVerificationCode: `Please enter verification code and then click Verify and Save`,
    MyDetails: `My Details`,
    ManagePassword: `Manage Password`,
    ManageSecurity: `Manage Multifactor Settings`,
    SelectMultiFactorOptionLabel: `Select Multifactor option. Click on the choices for more information.`,
    ManageYourProfileOnYourAccountSetting: `You can view and manage your profile using settings option in your organization settings.`,
    Note: "Note",
    PhoneNumNotLinkedDescription: "Looks like your phone no. is not linked or not in proper format for this account, don't worry you can enter it below and then click Activate.",
    PhoneNumLabel: "Enter your phone no.",
    ActivatePushAuthConfirmationTitle: "Activate Push Authentication",
    ActivatePushAuthConfirmation: "Before activating please make sure you have installed the Authy app, and set up your account with your phone no. and email. If you have already done it, click Proceed to activate push authentication.",
    ActivateTotpAuthConfirmationTitle: "Activate TOTP Authentication",
    ActivateTotpAuthConfirmation: "Before activating please make sure you have installed the Authenticator app which has support for TOTP. \n for eg. (Google Authenticator, Microsoft Authenticator, Authy app). If you already have it, click Proceed to activate TOTP authentication.",
    PasswordFieldName: 'password',
    UserDetails: `User Details`,
    LoginHistory: `Login History`,
    ManageNotification: `Manage Notification`
}