export default class PasswordStrengthResource {
    public static errorMessages = {
        invalidPassword: 'Please enter a password',
        emptyConfirmPassword: 'Please re-enter the password',
        invalidConfirmPassword: 'Password and Confirm Password should match'
    };
    public static labels = {
        passwordStrengthMeterLabel: 'Password strength',
        progressBarStatusTexts: {
            veryWeakPasswordText: 'Very weak',
            weakPasswordText: 'Weak',
            strongPasswordText: 'Strong',
            goodPasswordText: 'Good',
            default: ''
        },
        buttons: {
            yesButtonText: 'Yes',
            noButtonText: 'No'
        },
        passwordStrengthLabel: 'Password strength:',
        estimatedTimeToCrackLabel: 'Estimated time to crack:'
    };

    public static informations = {
        validationMessage: 'We recommend to use a strong password.',
        confirmationMessage: 'Do you still want to continue?'

    };
    public static passwordStrengthValues = {
        veryWeak: 'Very weak',
        weak: 'Weak',
        good: 'Good',
        strong: 'Strong'
    }
}