import CurrentUser from '../models/CurrentUser';
import ConnectionContext from '../utils/ConnectionContext';

const getCurrentUserValue = async (): Promise<CurrentUser | null> => {
    const connectionContext: ConnectionContext = new ConnectionContext(window.location.origin + "/api");
    try {        
        const currentUserResponse: CurrentUser = await connectionContext.get('/CurrentUser');
        return currentUserResponse;
    }
    catch (e) {
        return null;
    }
}

export const CurrentUserHelper = {
    getCurrentUserValue
}